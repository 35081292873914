import React, { Component } from 'react';
import { Panel } from "react-bootstrap";

import Spinner from '@sm/components/Spinner';

import '@assets/css/apiData.css';

import { DASHBOARD_MERCHANT_WITHOUT_ACTIVITY } from '@sm/actions/types';
import { Row } from "simple-flexbox";
const store =  require('@sm/reducers/index');

class DashBoardMerchantWithoutActivityComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      merchantsWithoutActivity: undefined,
      loading: false,
      socket: false
    };
  }

  subscribeFunction = null;

  componentDidMount() {
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      let merchantsWithoutActivity = undefined;
      if (state.merchantsWithoutActivity) {
        merchantsWithoutActivity = state.merchantsWithoutActivity;
      }

      if (state.update === DASHBOARD_MERCHANT_WITHOUT_ACTIVITY && this.props.thisMonth && state.merchantsWithoutActivity.socket) {
        this.setState({
          merchantsWithoutActivity: merchantsWithoutActivity,
          loading: false,
          socket: state.merchantsWithoutActivity.socket
        });
        return;
      }

      if (state.update === DASHBOARD_MERCHANT_WITHOUT_ACTIVITY && !state.merchantsWithoutActivity.socket) {
        this.setState({
          merchantsWithoutActivity: merchantsWithoutActivity,
          loading: false,
          socket: state.merchantsWithoutActivity.socket
        });
      }
      
    });
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  render() {
    const { merchantsWithoutActivity, loading } = this.state;

    let merchants = <Panel>
      <Panel.Heading>
        <Panel.Title> MERCHANTS WITH NO ACTIVITY </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <Spinner />
      </Panel.Body>
    </Panel>;

    if (!loading && merchantsWithoutActivity) {
      merchants = (
        <Panel className="merchants-without-activity">
          <Panel.Heading>
            <Panel.Title> MERCHANTS WITH NO ACTIVITY </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content">
              <Row style={ { width: "100%", display: 'inline-block'} }>
                { merchantsWithoutActivity.map((word, index) => {
                  return <div
                    className="merchant-name-without-activity"
                    key={ index }>
                    { word }
                  </div>;
                })}
              </Row>
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return merchants;
  }

}

export default DashBoardMerchantWithoutActivityComponent;