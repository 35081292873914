import React, { Component } from "react";
import Datetime from "react-datetime";
import { Row, Column } from "simple-flexbox";
import { Panel } from "react-bootstrap";
import TextareaAutosize from 'react-textarea-autosize';

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import PageHeader from "@sm/components/PageHeader";
import Form from "@sm/core/Form";
import ManualDataInput from "@modules/data/ManualDataInput";
import Multiselect from "@sm/components/custom/customMultiselect";
import Select from "@sm/components/custom/Select";
import Spinner from "@sm/components/Spinner";
import WithheldFunds from "@modules/data/WithheldFunds";

import "@assets/css/manualInput.css";
import Calendar from "@assets/images/calendar.png";
import ManualInputIcon from "@assets/images/notes.png";

import { crudActions } from "@sm/services/crudActions";
import { FETCH_PERMISSIONS } from "@sm/actions/types";
const store = require("@sm/reducers/index");

class ManualInput extends Component {
  fieldsMap = {
    SETTLEMENT_BY_DATE: [
      [
        {
          name: "currency",
          label: "MID Currency",
          disabled: true,
          fieldType: "input",
        },
        {
          name: "availableBalance",
          label: "Total Available Balance",
          fieldType: "input",
          disabled: true,
        },
        {
          name: "balanceToSettle",
          label: "Available Balance Reduction",
          disabled: true,
          fieldType: "input",
        },
        {
          name: "balanceToSettle",
          label: "Balance to Settle",
          disabled: true,
          fieldType: "input",
        },
        {
          name: "propertyId",
          label: "Status",
          fieldType: "select",
          items: "settlementByAmountStatuses",
        },
      ],
    ],
    SETTLEMENT_BY_AMOUNT: [
      [
        {
          name: "currency",
          label: "MID Currency",
          disabled: true,
          fieldType: "input",
        },
        {
          name: "availableBalance",
          label: "Total Available Balance",
          fieldType: "input",
          disabled: true,
        },
        {
          name: "requestedSettlement",
          label: "Available Balance Reduction",
          fieldType: "input",
        },
        {
          name: "balanceToSettle",
          label: "Balance to Settle",
          disabled: true,
          fieldType: "input",
        },
        {
          name: "propertyId",
          label: "Status",
          fieldType: "select",
          items: "settlementByAmountStatuses",
        },
      ],
    ],
    SETTLEMENT_BY_REQUEST: [
      [
        {
          name: "currency",
          label: "MID Currency",
          disabled: true,
          fieldType: "input",
          flexGrow: "2",
        },
        {
          name: "availableBalance",
          label: "Total Available Balance",
          disabled: true,
          fieldType: "input",
          flexGrow: "10",
        },
        {
          name: "requestedSettlement",
          label: "Requested Settlement",
          fieldType: "input",
          flexGrow: "10",
        },
        {
          name: "balanceToSettle",
          label: "Balance to Settle",
          disabled: true,
          fieldType: "input",
          flexGrow: "10",
        },
        {
          name: "propertyId",
          label: "Status",
          fieldType: "select",
          items: "settlementByRequestStatuses",
          flexGrow: "10",
        },
      ],
    ],
    UPDATE_SETTLEMENT: [
      [
        {
          name: "name",
          label: "MID",
          disabled: true,
          fieldType: "input",
        },
        {
          name: "fromDate",
          label: "From Date",
          disabled: true,
          fieldType: "date",
          display: "showDates",
        },
        {
          name: "toDate",
          label: "To Date",
          disabled: true,
          fieldType: "date",
          display: "showDates",
        },
        {
          name: "date",
          label: "Date",
          disabled: true,
          fieldType: "date",
        },
        {
          name: "balanceToSettle",
          label: "Balance to Settle",
          disabled: true,
          fieldType: "input",
        },
        {
          name: "propertyId",
          label: "Update Status",
          fieldType: "select",
          items: "updateSettlementStatuses",
        },
      ],
    ],
    TOP_UP_PAYOUT_WALLET: [
      [
        {
          name: "midCurrency",
          label: "MID Currency",
          disabled: true,
          fieldType: "input",
          flexGrow: "4",
        },
        {
          name: "availableBalance",
          label: "Available Balance",
          disabled: true,
          fieldType: "input",
          flexGrow: "10",
        },
        {
          name: "topUpAmount",
          label: "Top Up Amount",
          fieldType: "input",
          flexGrow: "10",
        }
      ]
    ],
    SETTLE_AGENT: [
      [
        {
          name: "amount",
          label: "Amount",
          fieldType: "input",
          flexGrow: "10",
          inputType: "number"
        },
        {
          name: "notes",
          label: "Notes",
          fieldType: "textarea",
          flexGrow: "10",
        }
      ]
    ]
  };

  formsMap = {
    SETTLEMENT_BY_DATE: "settlementFormByDate",
    SETTLEMENT_BY_AMOUNT: "settlementFormByAmount",
    SETTLEMENT_BY_REQUEST: "settlementFormByRequest",
    UPDATE_SETTLEMENT: "updateSettlementForm",
    TOP_UP_PAYOUT_WALLET: "topUpPayoutWalletForm",
    SETTLE_AGENT: "settleAgentForm"
  };

  state = {
    runningBalanceTypes: [],
    runningBalanceValues: [],
    settlementStatuses: [],
    settlementByAmountStatuses: [],
    settlementByRequestStatuses: [],
    settlementDates: [],

    settlementFormByDate: new Form(
      {
        availableBalance: "",
        balanceToSettle: "",
        // eslint-disable-next-line no-dupe-keys
        balanceToSettle: "",
        propertyId: "",
      },
      [
        {
          name: "availableBalance",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
        {
          name: "balanceToSettle",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
        {
          name: "balanceToSettle",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
        {
          name: "propertyId",
          type: "isString",
          rules: {
            required: true,
          },
        }
      ]
    ),

    settlementFormByAmount: new Form(
      {
        availableBalance: "",
        requestedSettlement: "",
        balanceToSettle: "",
        propertyId: "",
      },
      [
        {
          name: "availableBalance",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
        {
          name: "requestedSettlement",
          type: "isString",
          rules: {
            required: false,
          },
        },
        {
          name: "balanceToSettle",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
        {
          name: "propertyId",
          type: "isString",
          rules: {
            required: true,
          },
        }
      ]
    ),

    settlementFormByRequest: new Form(
      {
        availableBalance: null,
        requestedSettlement: null,
        balanceToSettle: null,
        propertyId: "",
      },
      [
        {
          name: "propertyId",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "availableBalance",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
        {
          name: "requestedSettlement",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "balanceToSettle",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
      ]
    ),

    updateSettlementForm: new Form(
      {
        propertyId: "",
      },
      [
        {
          name: "propertyId",
          type: "isString",
        },
      ]
    ),

    topUpPayoutWalletForm: new Form(
      {
        midCurrency: "",
        availableBalance: "",
        topUpAmount: ""
      },
      [
        {
          name: "midCurrency",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "availableBalance",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
        {
          name: "topUpAmount",
          type: "isString",
          rules: {
            required: true,
          },
        },
      ]
    ),

    settleAgentForm: new Form(
      {
        amount: "",
        notes: ""
      },
      [
        {
          name: "amount",
          type: "isNumber",
          rules: {
            required: true,
          },
        },
        {
          name: "notes",
          type: "isString",
          rules: {
            required: false,
          }
        }
      ]
    ),

    date: this.getPeriod(0).from,
    lastUpdate: Datetime.moment().utc().format("HH:mm - DD. MMM YYYY"),
    lastSettlementDate: "",
    loadingLookups: true,
    loadingDataInput: false,
    selectedMidCurrency: [],

    controls: {},
    controlsFormRules: [
      {
        name: "company",
        type: "isString",
        rules: {
          required: true,
        },
      },
      {
        name: "merchant",
        type: "isString",
        rules: {
          required: true,
        },
      },
      {
        name: "dataType",
        type: "isString",
        rules: {
          required: true,
        },
      }
    ],
    controlsFormData: {
      company: "",
      merchant: "",
      dataType: "",
      psp: "",
      midIds: "",
      settlementStatus: "",
      date: "",
    },

    companiesClients: {},
    companiesPsps: [],
    companiesBaseCurrencies: {},
    companies: [],
    psps: [],
    mids: [],
    dataTypes: [],
    notes: "",

    temporaryRunningBalanceItem: {},
    temporaryWithheldItem: {},
    savedData: {},

    settlementMids: [],

    access: [],

    showAlert: false,
    alertType: "",
    alertMessage: "",
    submitClicked: false,
    saveClicked: false,
    lockButton: false,
    enableReduce: false,
  };

  subscribeFunction = null;

  componentDidMount() {
    const { controlsFormRules, controlsFormData } = this.state;
    this.setState({
      controls: new Form(controlsFormData, controlsFormRules),
      loading: false,
      timeStamp: Datetime.moment().utc()
    });

    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access,
        });
      }
    });

    Promise.all([
      crudActions.get("v1/data/input/lookups"),
      crudActions.get("v1/agent/all")
    ]).then((info) => {
      const data = info[0];
      const agents = info[1];

      if (data) {
        const companies = data.whiteLabels.map((elem) => elem.whitelabel);
        companies.sort((elemA, elemB) =>
          elemA.label.localeCompare(elemB.label)
        );
        const runningBalanceTypes = data.dataTypes.find(
          (elem) => elem.id === "RUNNING_BALANCE"
        ).properties;
        const settlementStatuses = data.dataTypes.find(
          (elem) => elem.id === "SETTLEMENT_BY_DATE"
        ).properties;
        const midCurrency = data.mids.map((mid) => {
          return {
            value: mid.id,
            label: mid.currency,
          };
        });
        const uniqueCurrencies = [];

        const currency = midCurrency.filter((element) => {
          const isDuplicate = uniqueCurrencies.includes(element.label);

          if (!isDuplicate) {
            uniqueCurrencies.push(element.label);

            return true;
          }

          return false;
        });

        this.setState({
          companies: companies,
          controls: Object.assign(this.state.controls, {
            company: companies[0] ? companies[0].value : "",
          }),
          companiesClients: data.whiteLabels.reduce(
            (accumulator, currentElem) => {
              accumulator[currentElem.whitelabel.value] =
                  currentElem.merchants;
              return accumulator;
            },
            {}
          ),
          companiesPsps: data.whiteLabels.reduce(
            (accumulator, currentElem) => {
              accumulator[currentElem.whitelabel.value] = currentElem.psp;
              return accumulator;
            },
            {}
          ),
          companiesBaseCurrencies: data.whiteLabels.reduce(
            (accumulator, currentElem) => {
              accumulator[currentElem.whitelabel.value] =
                  currentElem.baseCurrencies.map((elem) => ({
                    value: elem,
                    label: elem,
                  }));
              return accumulator;
            },
            {}
          ),
          mids: data.mids.map((mid) => {
            return {
              value: mid.id,
              label: `${mid.name} (${mid.id})`,
              clients: mid.clients,
              pspId: mid.pspId,
              currency: mid.currency,
            };
          }),
          runningBalanceTypes: runningBalanceTypes,
          settlementStatuses: settlementStatuses,
          settlementByAmountStatuses: settlementStatuses.filter(
            (elem) => elem.label !== "Requested" && elem.label !== "Declined"
          ),
          settlementByRequestStatuses: settlementStatuses.filter(
            (elem) => elem.label !== "Requested" && elem.label !== "Paid"
          ),
          updateSettlementStatuses: settlementStatuses.filter(
            (elem) => elem.label !== "Requested"
          ),
          dataTypes: data.dataTypes.map((elem) => {
            return {
              value: elem.id,
              label: elem.name,
              supportedBy: elem.supportedBy
            };
          }),
          agents: agents,
          loadingLookups: false,
          currency: currency,
        });
      }
    })
      .catch((err) => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
          });
        }
      });
  }

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  }

  onConfirm = () => {
    const { showAlert, alertType } = this.state;
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: "",
    }, () => {
      if (showAlert && alertType === "success") {
        this.setState({
          showSecondPanel: false,
        });
      }
    });
  };

  getPeriod(periodType) {
    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().subtract(1, "day"),
        to: moment().utc().subtract(1, "day"),
      };
    case 2: // this month
      return {
        from: moment()
          .utc()
          .year(moment().utc().year())
          .month(moment().utc().month())
          .date(1),
        to: moment().utc(),
      };
    case 3: // last month
      return {
        from: moment()
          .utc()
          .year(moment().utc().year())
          .month(moment().utc().month() - 1)
          .date(1),
        to: moment()
          .utc()
          .year(moment().utc().year())
          .month(moment().utc().month() - 1)
          .date(
            moment()
              .utc()
              .month(moment().utc().month() - 1)
              .daysInMonth()
          ),
      };
    case 4: // this year
      return {
        from: moment().utc().year(moment().utc().year()).month(0).date(1),
        to: moment().utc(),
      };
    case 5: // last year
      return {
        from: moment()
          .utc()
          .year(moment().utc().year() - 1)
          .month(0)
          .date(1),
        to: moment()
          .utc()
          .year(moment().utc().year() - 1)
          .month(11)
          .date(31),
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc(),
        to: moment().utc(),
      };
    }
  }

  handleDateChange = (event) => {
    this.setState({ date: event });
  };

  defineControlsForm = (dataType) => {
    const { controls, controlsFormRules } = this.state;

    const copyArray = (arr) => {
      return JSON.parse(JSON.stringify(arr));
    };

    const runningBalanceForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      psp: "",
      midIds: "",
      updateSettlementDate: "",
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "psp",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "midIds",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "updateSettlementDate",
          type: "isString",
          rules: {
            required: false,
          },
        },
      ]
    ));

    const settlementByAmountForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      currency: "",
      psp: [],
      midIds: [],
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "currency",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "psp",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
          },
        },
        {
          name: "midIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
          },
        },
      ]
    ));

    const settlementByDateForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      date: Datetime.moment().format("DD.MM.YYYY"),
      currency: "",
      psp: [],
      midIds: [],
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "date",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "currency",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "psp",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
          },
        },
        {
          name: "midIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
          },
        },
      ]
    ));

    const settlementByRequestForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      currency: "",
      midIds: [],
      updateSettlementDate: "",
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "currency",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "midIds",
          type: "isArray",
          rules: {
            required: true,
          },
        },
        {
          name: "updateSettlementDate",
          type: "isString",
          rules: {
            required: true,
          },
        },
      ]
    ));

    const updateSettlementForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      settlementStatus: "",
      currency: "",
      midIds: [],
      updateSettlementDate: "",
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "settlementStatus",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "currency",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "midIds",
          type: "isArray",
          rules: {
            required: true,
          },
        },
        {
          name: "updateSettlementDate",
          type: "isString",
          rules: {
            required: true,
          },
        },
      ]
    ));

    const topUpPayoutWalletForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      psp: "",
      midIds: "",
      updateSettlementDate: "",
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "psp",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "midIds",
          type: "isNumber",
          rules: {
            required: true,
          },
        },
        {
          name: "updateSettlementDate",
          type: "isString",
          rules: {
            required: false,
          },
        },
      ]
    ));

    const withheldFundsForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      psp: "",
      midIds: "",
      updateSettlementDate: "",
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "psp",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "midIds",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "updateSettlementDate",
          type: "isString",
          rules: {
            required: false,
          },
        },
      ]
    ));

    const settleAgent = new Form({
      company: controls.company || "",
      dataType: controls.dataType || "",
      agent: controls.agent || "",
      date: this.state.timeStamp
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "date",
          type: "isDate",
          rules: {
            required: true,
          },
        },
        {
          name: "agent",
          type: "isString",
          rules: {
            required: true,
          },
        },
      ]
    ).map(el => el.name === "merchant" ? { ...el, rules: { required: false } } : el));

    return dataType === "RUNNING_BALANCE" ? runningBalanceForm :
      dataType === "SETTLEMENT_BY_AMOUNT" ? settlementByAmountForm :
        dataType === "SETTLEMENT_BY_DATE" ? settlementByDateForm :
          dataType === "TOP_UP_PAYOUT_WALLET" ? topUpPayoutWalletForm :
            dataType === "SETTLEMENT_BY_REQUEST" ? settlementByRequestForm :
              dataType === "SETTLE_AGENT" ? settleAgent :
                dataType === "WITHHELD_FUNDS" ? withheldFundsForm : updateSettlementForm;
  };

  onValueChange = (event, field) => {
    const { controls, submitClicked, mids } = this.state;
    let foundMid = [];
    if (!event) {
      controls[field] = event;
    } else if (event.target) {
      controls[field] = event.target.value;
    } else if (event.value) {
      if (field === "midIds" && (controls.dataType === "RUNNING_BALANCE" || controls.dataType === "WITHHELD_FUNDS")) {
        controls[field] = event.value.toString();
      } else {
        controls[field] = event.value;
      }
    } else {
      controls[field] = event.map((elem) => elem.value);
    }

    const pspField = controls.fieldRules.find(
      (fieldRule) => fieldRule.name === "psp"
    );
    const midIdsField = controls.fieldRules.find(
      (fieldRule) => fieldRule.name === "midIds"
    );

    if (field === "company") {
      controls.merchant = "";
      controls.psp = pspField && pspField.type === "isArray" ? [] : "";
      controls.midIds = midIdsField && midIdsField.type === "isArray" ? [] : "";
    } else if (field === "merchant") {
      controls.psp = pspField && pspField.type === "isArray" ? [] : "";
      controls.midIds = midIdsField && midIdsField.type === "isArray" ? [] : "";
    } else if (field === "psp") {
      controls.midIds = [];
      controls.midIds = midIdsField && midIdsField.type === "isArray" ? [] : "";
    } else if (field === "currency") {
      controls.midIds = midIdsField && midIdsField.type === "isArray" ? [] : "";
    } else if (field === "midIds" || field === "dataType") {
      if (controls.midIds && midIdsField.type === "isArray") {
        foundMid = mids.filter((elem) => controls["midIds"].includes(elem.value));
      }
      if (foundMid) {
        this.setState({
          selectedMidCurrency: foundMid,
        });
      }
      if (field === "dataType") {
        this.setState({
          notes: ""
        });
        this.changeRequestedSettlementFieldStatus(event.value ,false);

        if (event.value === "TOP_UP_PAYOUT_WALLET") {
          this.setState({
            date: Datetime.moment()
          });
        }
      }
    }

    if (field !== "updateSettlementDate") {
      this.loadLastDate();
    }

    if (submitClicked) {
      controls.isFormValid();
    }

    this.setState({
      controls: field === "dataType" ? this.defineControlsForm(controls.dataType) : controls
    });
  };

  loadLastDate = () => {
    const { controls } = this.state;

    const dateNow = Datetime.moment();

    if (
      controls.midIds &&
      controls.midIds.length &&
      controls.merchant &&
      controls.dataType === "SETTLEMENT_BY_DATE"
    ) {
      crudActions
        .get(
          `v1/data/input/settlements?midId=${controls.midIds}&clientId=${controls.merchant}`
        )
        .then((response) => {
          if (response) {
            const lastSettlementDate = response.data
              ? Datetime.moment(response.data, "DD.MM.YYYY")
              : "";

            this.setState({
              lastSettlementDate
            });
          }
        })
        .catch((err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }

          this.setState({
            lastSettlementDate: ""
          });
        });
    }

    if (
      controls.dataType === "UPDATE_SETTLEMENT" &&
      controls.midIds &&
      controls.merchant &&
      controls.settlementStatus
    ) {
      crudActions
        .get(
          `v1/data/input/dropdown-dates?midIds=${controls.midIds}&clientId=${controls.merchant}&propertyId=${controls.settlementStatus}`
        )
        .then((dates) => {
          if (dates) {
            this.setState({
              settlementDates: dates,
              controls: Object.assign(controls, {
                updateSettlementDate: dates.length ? dates[0].value : "",
              }),
            });
          }
        })
        .catch((err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        });
    }

    if (
      controls.dataType === "SETTLEMENT_BY_REQUEST" &&
      controls.midIds.length &&
      controls.merchant &&
      controls.currency
    ) {
      crudActions
        .get(
          `v1/data/input/dropdown-dates/requested?midIds=${controls.midIds}&clientId=${controls.merchant}&currency=${controls.currency}`
        )
        .then((dates) => {
          if (dates) {
            this.setState({
              settlementDates: dates,
              controls: Object.assign(this.state.controls, {
                updateSettlementDate: dates.length ? dates[0].value : "",
              }),
            });
          }
        })
        .catch((err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        });
    }

    this.setState({
      dateNow
    });
  };

  isPrecise = (num) => {
    return String(num).split(".")[1]?.length <= 2;
  };

  onSettlementChange = (event, field, settlementFormName, id) => {
    const settlementForm = this.state[settlementFormName];

    const { controls, saveClicked, savedData, savedDataList, settlementMids } = this.state;
    if (controls.dataType === "SETTLE_AGENT" && field === "notes") {
      settlementForm[field] = event.target.value;
    } else {
      const isNumber =
      settlementForm.fieldRules.find((rule) => rule.name === field).type ===
      "isNumber";
      const numberRegex = new RegExp(/^\d+$/);
      if (!event) {
        const matchNumber = event.match(numberRegex);
        settlementForm[field] = isNumber
          ? parseInt(matchNumber ? matchNumber[0] : settlementForm[field])
          : event;
      } else if (event.target) {
        const regex = controls.dataType === "TOP_UP_PAYOUT_WALLET" ? /[^0-9\-.]/g : /[^0-9.]/g;
        const value = event.target.value.replace(regex, "");
        if (((
          controls.dataType === "SETTLEMENT_BY_AMOUNT" ||
            controls.dataType === "SETTLEMENT_BY_DATE" ||
            controls.dataType === "SETTLEMENT_BY_REQUEST") &&
          field === "requestedSettlement") ||
        (controls.dataType === "TOP_UP_PAYOUT_WALLET" &&
          field === "topUpAmount")
        ) {
          if (value <= settlementForm.availableBalance || value === "-") {
            if (value.indexOf(".") > -1) {
              if (this.isPrecise(value)) {
                settlementForm[field] = value;
              }
            } else {
              settlementForm[field] = value;
            }
          }
        } else {
          settlementForm[field] = value ? parseFloat(value) : value;
        }
      } else if (
        settlementFormName === "updateSettlementForm" &&
      field === "propertyId"
      ) {
        savedDataList.find((elem) => elem.id === id).propertyId = parseInt(event.value);
      } else if (event.value) {
        const matchNumber = event.value.match(numberRegex);
        settlementForm[field] = isNumber
          ? parseInt(matchNumber ? matchNumber[0] : settlementForm[field])
          : event.value;
      } else {
        settlementForm[field] = event.map((elem) => elem.value);
      }

      if (controls.dataType === "SETTLEMENT_BY_AMOUNT") {
        const balanceToSettleField = settlementForm.fieldRules.find(
          (fieldRule) => fieldRule.name === "balanceToSettle"
        );
        balanceToSettleField.rules.max = savedData.runningBalanceAmount;
      }

      if ((
        controls.dataType === "SETTLEMENT_BY_AMOUNT" ||
        controls.dataType === "SETTLEMENT_BY_DATE" ||
        controls.dataType === "SETTLEMENT_BY_REQUEST") &&
      field === "requestedSettlement"
      ) {
        settlementForm.balanceToSettle = 0;
        for (let i = 0; i < settlementMids.length; i++) {
          settlementMids[i].balanceToSettle = 0;
          settlementMids[i].isChecked = false;
        }
      }

      if (field === "balanceToSettle") {
        settlementForm[field] = parseInt(settlementForm[field], 10);
      }
    }
    if (saveClicked) {
      settlementForm.isFormValid();
    }

    this.setState({
      [settlementFormName]: settlementForm,
      savedDataList: savedDataList,
      settlementMids
    });
  };

  onMidRowChange = (event, field, index) => {
    const { controls, settlementMids } = this.state;
    const settlementFormName = this.formsMap[controls.dataType];
    const settlementForm = this.state[settlementFormName];

    if (field === "balanceToSettle") {
      const value = event.target.value.replace(/[^0-9.]/g, "");
      if (value <= settlementMids[index].availableBalance) {
        if (value !== "") {
          if (value.indexOf(".") > -1) {
            if (this.isPrecise(value)) {
              settlementMids[index][field] = value;
            }
          } else {
            settlementMids[index][field] = value;
          }
          settlementMids[index].isChecked = parseFloat(value) === settlementMids[index].availableBalance;
          let sumToSettle = 0;
          for (let i = 0; i < settlementMids.length; i++) {
            if (settlementMids[i].balanceToSettle) {
              sumToSettle += parseFloat(parseFloat(settlementMids[i].balanceToSettle).toFixed(2));
            }
          }
          settlementForm.balanceToSettle = parseFloat(sumToSettle.toFixed(2));
        } else {
          settlementMids[index][field] = "";
          settlementMids[index].isChecked = false;
          let sumToSettle = 0;
          for (let i = 0; i < settlementMids.length; i++) {
            if (settlementMids[i].balanceToSettle) {
              sumToSettle += parseFloat(parseFloat(settlementMids[i].balanceToSettle).toFixed(2));
            }
          }
          settlementForm.balanceToSettle = parseFloat(sumToSettle.toFixed(2));
        }

      }
      settlementForm.requestedSettlement = "";
      const midsZeroSettle = settlementMids.filter(f => f.balanceToSettle > 0);
      this.changeRequestedSettlementFieldStatus(controls.dataType, midsZeroSettle.length > 0);
    }
    this.setState({
      settlementMids
    });
  };

  onChangeFilter = () => {
    const {
      controls,
      date,
      companiesBaseCurrencies,
      settlementFormByDate,
      settlementFormByAmount,
      settlementFormByRequest,
      settlementByAmountStatuses,
      updateSettlementForm,
      topUpPayoutWalletForm,
      selectedMidCurrency,
      mids
    } = this.state;
    const isFormValid = controls.isFormValid();

    this.setState({
      controls,
      submitClicked: true,
      lockButton: isFormValid,
      loadingDataInput: isFormValid,
    });

    const formattedDate = Datetime.moment(date).format("DD.MM.YYYY");
    
    if (isFormValid) {
      const {
        merchant,
        dataType,
        mid,
        midIds,
        updateSettlementDate,
        settlementStatus,
      } = controls;

      if (dataType === 'SETTLE_AGENT') {
        this.setState({
          savedData: controls.data(),
          lockButton: false,
          showSecondPanel: true,
          loadingDataInput: false
        });
      } else {

        this.changeRequestedSettlementFieldStatus(dataType, false);

        let url = `v1/data/input?clientId=${ merchant }&dataInputTypes=${ dataType }&midIds=${ midIds }`;
        if (controls.dataType === "RUNNING_BALANCE" || controls.dataType === "TOP_UP_PAYOUT_WALLET" || controls.dataType === "WITHHELD_FUNDS") {
          url += `&date=${ formattedDate }`;
        }
        if (controls.dataType === "SETTLEMENT_BY_DATE") {
          url += `&date=${ controls.date }`;
        }
        if (controls.dataType === "UPDATE_SETTLEMENT") {
          url += `&propertyId=${ settlementStatus }&date=${ updateSettlementDate }`;
        }
        if (controls.dataType === "SETTLEMENT_BY_REQUEST") {
          url += `&currency=${ controls.currency }&date=${ updateSettlementDate }`;
        }

        crudActions.get(url)
          .then((response) => {
            if (controls.dataType === "RUNNING_BALANCE") {
              this.setState({
                runningBalanceValues: response,
                showSecondPanel: true,
                savedData: Object.assign(controls.data(), {
                  date: formattedDate,
                  baseCurrencies:
                    companiesBaseCurrencies[controls.company] || [],
                }),
                loadingDataInput: false,
                lockButton: false,
              });
            } else if (controls.dataType === "WITHHELD_FUNDS") {
              this.setState({
                withheldFunds: response,
                showSecondPanel: true,
                savedData: Object.assign(controls.data(), {
                  date: formattedDate,
                  currency: mids && mids.find(item => item.value === Number(controls.midIds)).currency
                }),
                loadingDataInput: false,
                lockButton: false
              });
            } else if (controls.dataType === "SETTLEMENT_BY_DATE") {
              const responseMids = response.map((resp, index) => {
                return {
                  id: resp.midId,
                  name: `${ resp.name } (${ resp.midId })` || '',
                  date: resp.date || '',
                  fromDate: resp.fromDate || '',
                  toDate: resp.toDate || '',
                  pendingPayouts: resp.pendingPayouts || 0,
                  availableBalance: resp.runningBalanceAmount || 0,
                  balanceToSettle: resp.runningBalanceAmount || 0,
                  providerId: resp.providerId || '',
                  providerName: resp.providerName || '',
                  disabled: false,
                  isChecked: true
                };
              });
              if (responseMids.length) {
                let availableBalance = 0;
                for (let i = 0; i < responseMids.length; i++) {
                  const midBalance = parseFloat(responseMids[i].availableBalance);
                  availableBalance += midBalance;
                }
                this.changeRequestedSettlementFieldStatus(controls.dataType, true);
                this.setState({
                  settlementFormByDate: Object.assign(settlementFormByDate, {
                    availableBalance: parseFloat((availableBalance).toFixed(2)) || 0,
                    propertyId: "17",
                    currency: controls.currency || "",
                    requestedSettlement: availableBalance.toFixed(2).toString() || 0,
                    balanceToSettle: parseFloat((availableBalance).toFixed(2)) || 0
                  }),
                  showSecondPanel: true,
                  savedData: Object.assign(controls.data(), {
                    date: formattedDate,
                    baseCurrencies: companiesBaseCurrencies[controls.company] || [],
                    currency: "",
                  }),
                  settlementMids: responseMids,
                  loadingDataInput: false,
                  lockButton: false
                });
              } else {
                this.setState({
                  showAlert: true,
                  alertType: "error",
                  alertMessage: "The selected MIDs has no available balance.",
                  showSecondPanel: false,
                  loadingDataInput: false,
                  lockButton: false
                });
              }
            } else if (controls.dataType === "SETTLEMENT_BY_AMOUNT") {
              const midCurrency = selectedMidCurrency.find(elem => elem.value === mid);
              const responseMids = response.map((resp, index) => {
                return {
                  id: resp.id,
                  name: `${ resp.name } (${ resp.id })` || '',
                  date: resp.date || '',
                  pendingPayouts: resp.pendingPayouts || 0,
                  availableBalance: resp.availableBalance || 0,
                  payoutWalletBalance: resp.walletBalance || 0,
                  payoutWalletType: resp.payoutWalletType || "",
                  balanceToSettle: 0,
                  providerName: resp.providerName || '',
                  withheldFunds: resp.withheldFunds || 0,
                  disabled: false,
                  isChecked: false
                };
              });
              if (responseMids.length) {
                let availableBalance = 0;
                for (let i = 0; i < responseMids.length; i++) {
                  const midBalance = parseFloat(responseMids[i].availableBalance);
                  availableBalance += midBalance;
                }
                this.setState({
                  settlementFormByAmount: Object.assign(settlementFormByAmount, {
                    availableBalance: parseFloat((availableBalance).toFixed(2)) || 0,
                    propertyId: "17",
                    currency: controls.currency || "",
                    requestedSettlement: "",
                    balanceToSettle: 0
                  }),
                  showSecondPanel: true,
                  savedData: Object.assign(controls.data(), {
                    date: formattedDate,
                    baseCurrencies: companiesBaseCurrencies[controls.company] || [],
                    midCurrency: midCurrency ? midCurrency.currency : [],
                    currency: "",
                    settlementByAmountStatuses: settlementByAmountStatuses,
                  }),
                  settlementMids: responseMids,
                  loadingDataInput: false,
                  lockButton: false,
                  notes: ""
                });
              } else {
                this.setState({
                  showAlert: true,
                  alertType: "error",
                  alertMessage: "The selected MIDs has no available balance.",
                  showSecondPanel: false,
                  loadingDataInput: false,
                  lockButton: false
                });
              }
            } else if (controls.dataType === "SETTLEMENT_BY_REQUEST") {
              crudActions.get(`v1/data/input?clientId=${ merchant }&dataInputTypes=SETTLEMENT_BY_AMOUNT&midIds=${ midIds }&date=${ updateSettlementDate }&currency=${ controls.currency }`)
                .then((midsResponse) => {
                  const responseMids = midsResponse.map((resp, index) => {
                    return {
                      id: resp.id,
                      name: `${ resp.name } (${ resp.id })` || '',
                      payoutWalletBalance: resp.walletBalance || 0,
                      payoutWalletType: resp.payoutWalletType || "",
                      pendingPayouts: resp.pendingPayouts || 0,
                      availableBalance: resp.availableBalance || 0,
                      balanceToSettle: 0,
                      providerName: resp.providerName || '',
                      disabled: false,
                      isChecked: false
                    };
                  });
                  if (responseMids.length) {
                    let availableBalance = 0;
                    for (let i = 0; i < midsResponse.length; i++) {
                      const midBalance = parseFloat(midsResponse[i].availableBalance);
                      availableBalance += midBalance;
                    }
                    this.setState({
                      settlementFormByRequest: Object.assign(settlementFormByRequest, {
                        availableBalance: parseFloat((availableBalance).toFixed(2)),
                        balanceToSettle: 0,
                        propertyId: "17",
                        requestedSettlement: parseFloat(response.balanceToSettle).toFixed() || 0,
                        currency: controls.currency || "",
                      }),
                      showSecondPanel: true,
                      savedData: Object.assign(controls.data(), {
                        settlementRequestId: response.id,
                        date: formattedDate,
                        baseCurrencies: companiesBaseCurrencies[controls.company] || [],
                        midCurrency: selectedMidCurrency,
                        currency: "",
                      }),
                      loadingDataInput: false,
                      lockButton: false,
                      settlementMids: responseMids,
                      notes: ""
                    });
                  } else {
                    this.setState({
                      showAlert: true,
                      alertType: "error",
                      alertMessage: "There is no available balance on the selected MIDs.",
                      showSecondPanel: false,
                      loadingDataInput: false,
                      lockButton: false
                    });
                  }
                }).catch((err) => {
                  if (err && err.message) {
                    this.setState({
                      showAlert: true,
                      alertType: "error",
                      alertMessage: err.message
                    });
                  }

                  this.setState({
                    loadingDataInput: false,
                    lockButton: false,
                  });
                });
            } else if (controls.dataType === "UPDATE_SETTLEMENT") {
              this.setState({
                updateSettlementForm: Object.assign(updateSettlementForm, {
                  propertyId: response.propertyId
                    ? response.propertyId.toString()
                    : "",
                }),
                showSecondPanel: true,
                savedData: Object.assign(controls.data()),
                savedDataList: response.map((resp) => {
                  return {
                    date: resp.date,
                    fromDate: resp.fromDate,
                    toDate: resp.toDate,
                    id: resp.id,
                    name: resp.name,
                    midId: resp.midId,
                    propertyId: resp.propertyId,
                    clientId: resp.clientId,
                    balanceToSettle: resp.balanceToSettle,
                    runningBalanceAmount: resp.runningBalanceAmount,
                  };
                }),

                showDates: !!(response.fromDate || response.toDate),
                loadingDataInput: false,
                lockButton: false,
              });
            } else if (controls.dataType === "TOP_UP_PAYOUT_WALLET") {
              this.setState({
                topUpPayoutWalletForm: Object.assign(topUpPayoutWalletForm, {
                  midCurrency: response.currency || "",
                  availableBalance: response.availableBalanceAmount || 0,
                  topUpAmount: ""
                }),
                savedData: Object.assign(controls.data(), {
                  topUpDate: response.date || "",
                  merchantId: response.merchantId || undefined,
                  midId: response.midId || undefined,
                }),
                showSecondPanel: true,
                loadingDataInput: false,
                lockButton: false,
              });
            }
          })
          .catch((err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message
              });
            }

            this.setState({
              loadingDataInput: false,
              lockButton: false
            });
          });
      }
    }
  };

  onMidSelect = (index, dataType) => {
    const settlementFormName = this.formsMap[dataType];
    const settlementForm = this.state[settlementFormName];
    const { settlementMids } = this.state;
    const checkedState = settlementMids[index].isChecked;
    settlementMids[index].isChecked = !checkedState;

    if (settlementMids[index].isChecked) {
      if (settlementForm.requestedSettlement !== "") {
        if (
          parseFloat(settlementForm.balanceToSettle.toFixed(2)) < parseFloat(parseFloat(settlementForm.requestedSettlement).toFixed(2))
        ) {
          const diff = parseFloat(parseFloat(settlementForm.requestedSettlement).toFixed(2)) - parseFloat(settlementForm.balanceToSettle.toFixed(2));
          if (diff > settlementMids[index].availableBalance) {
            settlementMids[index].balanceToSettle = parseFloat(settlementMids[index].availableBalance.toFixed(2));
            settlementForm.balanceToSettle = parseFloat(settlementForm.balanceToSettle.toFixed(2)) + parseFloat(settlementMids[index].availableBalance.toFixed(2));
          } else {
            const balanceDiff = Math.abs(
              parseFloat(settlementForm.balanceToSettle.toFixed(2)) -
                parseFloat(parseFloat(settlementForm.requestedSettlement).toFixed(2))
            );
            settlementMids[index].balanceToSettle = parseFloat(balanceDiff.toFixed(2));
            settlementForm.balanceToSettle = parseFloat(settlementForm.balanceToSettle.toFixed(2)) + parseFloat(balanceDiff.toFixed(2));
            for (let i = 0; i < settlementMids.length; i++) {
              settlementMids[i].disabled = settlementMids[i].balanceToSettle === 0;
            }
          }
        } else {
          for (let i = 0; i < settlementMids.length; i++) {
            settlementMids[i].disabled = settlementMids[i].balanceToSettle === 0;
          }
        }
      } else {
        settlementMids[index].balanceToSettle = parseFloat(settlementMids[index].availableBalance.toFixed(2));
        let sumToSettle = 0;
        for (let i = 0; i < settlementMids.length; i++) {
          sumToSettle += parseFloat(parseFloat(settlementMids[i].balanceToSettle).toFixed(2));
        }
        settlementForm.balanceToSettle = parseFloat(sumToSettle.toFixed(2));
      }
      this.changeRequestedSettlementFieldStatus(dataType, true);
    } else {
      settlementMids[index].balanceToSettle = 0;
      let sumToSettle = 0;
      for (let i = 0; i < settlementMids.length; i++) {
        settlementMids[i].disabled = false;
        sumToSettle += parseFloat(parseFloat(settlementMids[i].balanceToSettle).toFixed(2));
      }
      settlementForm.balanceToSettle = parseFloat(sumToSettle.toFixed(2));
      if (settlementForm.balanceToSettle === 0 && dataType !== "SETTLEMENT_BY_DATE") {
        this.changeRequestedSettlementFieldStatus(dataType, false);
      }
    }

    this.setState({ settlementMids, [settlementFormName]: settlementForm });
  };

  changeRequestedSettlementFieldStatus = (dataType, status) => {
    const fields = this.fieldsMap[dataType];
    if (dataType === "SETTLEMENT_BY_AMOUNT" || dataType === "SETTLEMENT_BY_REQUEST") {
      const fieldIndex = fields[0].findIndex(item => item.name === "requestedSettlement");
      fields[0][fieldIndex].disabled = status;
    }
  };

  filterMids = () => {
    const { controls, mids } = this.state;
    if (!controls.psp || !controls.merchant) {
      return [];
    }

    const filteredMidsByMerchant = mids.filter(
      (mid) =>
        mid.clients.some(
          (client) => client.clientId === parseInt(controls.merchant, 10)
        )
    );

    const filteredMids = filteredMidsByMerchant.filter(
      (mid) => typeof controls["psp"] !== "string" ?
        controls["psp"].includes(mid.pspId.toString()) :
        controls["psp"] === mid.pspId.toString()
    );

    return filteredMids;
  };

  filterMidsByCurrency = () => {
    const { controls, mids } = this.state;
    if (!controls.merchant) {
      return [];
    }
    return mids.filter(
      (mid) =>
        mid.clients.some(
          (client) => client.clientId === parseInt(controls.merchant, 10)
        ) && mid.currency === controls.currency
    );
  };

  onTypesTemporaryChange = (temporaryItem) => {
    this.setState({
      temporaryRunningBalanceItem: temporaryItem
    });
  };

  onTypesChange = (selectedItems) => {
    if (selectedItems) {
      this.setState({
        runningBalanceValues: selectedItems,
      });
    }
  };

  onWithheldTemporaryChange = (temporaryItem) => {
    this.setState({
      temporaryWithheldItem: temporaryItem
    });
  };

  onWithheldChange = (funds) => {
    this.setState({
      withheldFunds: funds
    });
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(
      (elem) => elem.permission === permissionName
    );
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  mapSelectedItems = (type, company) => {
    const field = this.state.controls[type];
    const { companiesPsps } = this.state;
    const lookup = companiesPsps[company];

    return field.map((elem) => {
      return lookup.find((lookupElem) => elem === lookupElem.value);
    });
  };

  mapSelectedMids = () => {
    const { controls, mids } = this.state;
    return controls.midIds
      ? mids.filter((elem) => controls.midIds.indexOf(elem.value) > -1)
      : [];
  };

  mapSelectedMidsByCurrency = () => {
    const { controls, mids } = this.state;
    return controls.mid
      ? mids.filter((elem) => controls.mid.indexOf(elem.value) > -1)
      : [];
  };

  onSaveDataEntries = () => {
    const {
      savedData,
      runningBalanceValues,
      temporaryRunningBalanceItem,
      settlementFormByDate,
      settlementFormByAmount,
      settlementFormByRequest,
      controls,
      updateSettlementForm,
      savedDataList,
      settlementMids,
      topUpPayoutWalletForm,
      settleAgentForm,
      notes,
      temporaryWithheldItem,
      withheldFunds
    } = this.state;

    if (savedData.dataType === "RUNNING_BALANCE") {
      if (temporaryRunningBalanceItem.id && temporaryRunningBalanceItem.value) {
        runningBalanceValues.push(temporaryRunningBalanceItem);
      }

      this.setState({
        lockButton: true,
        loadingDataInput: true
      });

      crudActions
        .put(`v1/data/input`, {
          data: runningBalanceValues,
          clientId: savedData.merchant,
          midId: savedData.midIds,
          date: savedData.date,
        })
        .then(() => {
          this.setState({
            controls: Object.assign(controls, {
              merchant: null,
              psp: null,
              midIds: null,
              dataType: null,
            }),
            temporaryRunningBalanceItem: {},
            showAlert: true,
            alertType: "success",
            alertMessage: "Settlement successfully initiated.",
            lockButton: false,
            loadingDataInput: false
          });
        })
        .catch((err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
            });
          }

          this.setState({
            lockButton: false,
            loadingDataInput: false
          });
        });
    } else if (savedData.dataType === "WITHHELD_FUNDS") {
      if (temporaryWithheldItem.amount) {
        withheldFunds.push(temporaryWithheldItem);
        this.setState({
          temporaryWithheldItem: {}
        });
      }

      if (withheldFunds) {
        withheldFunds.forEach(fund => {
          if (!fund.clientId) {
            fund.clientId = Number(savedData.merchant);
          }
          if (!fund.midId) {
            fund.midId = Number(savedData.midIds);
          }
        });
      }

      this.setState({
        lockButton: true,
        loadingDataInput: true
      });

      crudActions.post(`v1/data/input/withheld-funds`, withheldFunds)
        .then(() => {
          this.setState({
            controls: Object.assign(controls, {
              merchant: null,
              psp: null,
              midIds: null,
              dataType: null,
            }),
            showAlert: true,
            alertType: "success",
            alertMessage: "Operation successfull.",
            lockButton: false,
            loadingDataInput: false
          });
        })
        .catch((err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }

          this.setState({
            lockButton: false,
            loadingDataInput: false
          });
        });
    } else if (savedData.dataType === "SETTLEMENT_BY_DATE") {
      const isSettlementFormValid = settlementFormByDate.isFormValid();

      this.setState({
        saveClicked: true,
        settlementFormByDate,
        lockButton: isSettlementFormValid,
        loadingDataInput: isSettlementFormValid
      });

      if (isSettlementFormValid) {
        if (settlementFormByDate.balanceToSettle > 0) {
          const data = settlementMids.filter(m => m.isChecked && m.balanceToSettle > 0).map((mid) => {
            return {
              id: mid.id,
              name: mid.name,
              pendingPayouts: mid.pendingPayouts,
              runningBalanceAmount: mid.availableBalance,
              balanceToSettle: mid.balanceToSettle,
              currency: settlementFormByDate.currency,
              date: mid.date,
              fromDate: mid.fromDate,
              toDate: mid.toDate,
              midId: mid.id,
              providerId: mid.providerId,
              providerName: mid.providerName,
              clientId: savedData.merchant,
              propertyId: settlementFormByDate.propertyId
            };
          });

          crudActions
            .post(
              `v1/data/input/settlements`,
              data
            )
            .then(() => {
              this.setState({
                controls: Object.assign(controls, {
                  merchant: null,
                  psp: null,
                  midIds: null,
                  dataType: null,
                }),
                showAlert: true,
                alertType: "success",
                alertMessage: "Settlement successfully initiated.",
                lockButton: false,
                loadingDataInput: false
              });
            })
            .catch((err) => {
              if (err && err.message) {
                this.setState({
                  showAlert: true,
                  alertType: "error",
                  alertMessage: err.message
                });
              }

              this.setState({
                lockButton: false,
                loadingDataInput: false
              });
            });
        } else {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: 'Balance to Settle value should be greater than 0! Please, select at least one MID to proceed.',
            lockButton: false,
            loadingDataInput: false
          });
        }
      }
    } else if (savedData.dataType === "SETTLEMENT_BY_AMOUNT") {
      const isSettlementFormValid = settlementFormByAmount.isFormValid();
      this.setState({
        saveClicked: true,
        settlementFormByAmount,
        lockButton: isSettlementFormValid,
        loadingDataInput: isSettlementFormValid && settlementFormByAmount.balanceToSettle > 0
      });

      if (isSettlementFormValid) {
        if (settlementFormByAmount.balanceToSettle > 0) {
          const data = settlementMids.filter(m => parseFloat(m.balanceToSettle) > 0).map((mid) => {
            return {
              id: mid.id,
              name: mid.name,
              walletBalance: mid.payoutWalletBalance,
              pendingPayouts: mid.pendingPayouts,
              availableBalance: mid.availableBalance,
              balanceToSettle: mid.balanceToSettle,
              currency: settlementFormByAmount.currency,
              date: mid.date,
              midId: mid.id,
              clientId: savedData.merchant,
              propertyId: settlementFormByAmount.propertyId,
              notes
            };
          });

          crudActions
            .post(
              `v1/data/input/settlements/amount`,
              data
            )
            .then(() => {
              this.setState({
                controls: Object.assign(controls, {
                  merchant: null,
                  psp: null,
                  midIds: null,
                  dataType: null,
                }),
                showAlert: true,
                alertType: "success",
                alertMessage: "Settlement successfully initiated.",
                lockButton: false,
                loadingDataInput: false,
                notes: ""
              });
            })
            .catch((err) => {
              if (err && err.message) {
                this.setState({
                  showAlert: true,
                  alertType: "error",
                  alertMessage: err.message,
                });
              }

              this.setState({
                lockButton: false,
                loadingDataInput: false
              });
            });
        } else {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: 'Balance to Settle value should be greater than 0! Please, select at least one MID to proceed.',
            lockButton: false,
            loadingDataInput: false
          });
        }
          
      }
    } else if (savedData.dataType === "SETTLEMENT_BY_REQUEST") {
      const isSettlementFormValid = settlementFormByRequest.isFormValid();
      this.setState({
        saveClicked: true,
        settlementFormByRequest,
        lockButton: isSettlementFormValid,
        loadingDataInput: isSettlementFormValid && settlementFormByRequest.balanceToSettle > 0
      });

      if (isSettlementFormValid) {
        if (settlementFormByRequest.balanceToSettle > 0 || settlementFormByRequest.propertyId === "19") {
          const data = settlementMids.filter(m => (m.isChecked && m.balanceToSettle > 0 && settlementFormByRequest.propertyId !== "19") || (m.isChecked && settlementFormByRequest.propertyId === "19")).map((mid) => {
            return {
              id: mid.id,
              name: mid.name,
              walletBalance: mid.payoutWalletBalance,
              pendingPayouts: mid.pendingPayouts,
              availableBalance: mid.availableBalance,
              balanceToSettle: mid.balanceToSettle,
              currency: settlementFormByRequest.currency,
              date: savedData.date,
              midId: mid.id,
              clientId: savedData.merchant,
              propertyId: settlementFormByRequest.propertyId,
              notes
            };
          });
          if (!data.length) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: 'Please select a mid - even if you intend to decline a settlement.',
              lockButton: false,
              loadingDataInput: false
            });
            return;
          }
          crudActions
            .post(
              `v1/data/input/settlements/submit`,
              {
                settlements: data,
                id: savedData.settlementRequestId,
                amount: settlementFormByRequest.requestedSettlement,
                propertyId: settlementFormByRequest.propertyId
              }
            )
            .then(() => {
              this.setState({
                controls: Object.assign(controls, {
                  merchant: null,
                  psp: null,
                  midIds: null,
                  dataType: null,
                }),
                showAlert: true,
                alertType: "success",
                alertMessage: settlementFormByRequest.propertyId === "19" ? "Settlement declined." : "Settlement successfully initiated.",
                lockButton: false,
                loadingDataInput: false,
                notes: ""
              });
            })
            .catch((err) => {
              if (err && err.message) {
                this.setState({
                  showAlert: true,
                  alertType: "error",
                  alertMessage: err.message
                });
              }

              this.setState({
                lockButton: false,
                loadingDataInput: false
              });
            });
        } else {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: 'Please select a mid - even if you intend to decline a settlement.',
            lockButton: false,
            loadingDataInput: false
          });
        }
      }
    } else if (savedData.dataType === "UPDATE_SETTLEMENT") {
      const isSettlementFormValid = updateSettlementForm.isFormValid();
      this.setState({
        saveClicked: true,
        updateSettlementForm,
        lockButton: isSettlementFormValid,
        loadingDataInput: isSettlementFormValid
      });

      if (isSettlementFormValid) {
        crudActions
          .put(
            `v1/data/input/settlements`,
            savedDataList,
          )
          .then(() => {
            this.setState({
              controls: Object.assign(controls, {
                merchant: null,
                psp: null,
                midIds: null,
                dataType: null,
              }),
              showAlert: true,
              alertType: "success",
              alertMessage: "Operation successfull.",
              lockButton: false,
              loadingDataInput: false
            });
          })
          .catch((err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message
              });
            }

            this.setState({
              lockButton: false,
              loadingDataInput: false
            });
          });
      }
    } else if (savedData.dataType === "TOP_UP_PAYOUT_WALLET") {
      const isTopUpPayoutWalletFormValid = topUpPayoutWalletForm.isFormValid();
      this.setState({
        saveClicked: true,
        topUpPayoutWalletForm,
        lockButton: isTopUpPayoutWalletFormValid,
        loadingDataInput: isTopUpPayoutWalletFormValid
      });

      if (isTopUpPayoutWalletFormValid) {
        const data = {
          merchantId: savedData.merchantId,
          midId: savedData.midId,
          date: savedData.topUpDate,
          topUpAmount: parseFloat(topUpPayoutWalletForm.topUpAmount, 2),
          availableBalanceAmount: topUpPayoutWalletForm.availableBalance,
          currency: topUpPayoutWalletForm.midCurrency,
        };

        crudActions
          .post(
            `v1/data/input/payout/top-up`,
            data,
          )
          .then(() => {
            this.setState({
              controls: Object.assign(controls, {
                merchant: null,
                psp: null,
                midIds: null,
                dataType: null,
              }),
              savedData: {},
              showAlert: true,
              alertType: "success",
              alertMessage: "Operation successfull.",
              lockButton: false,
              loadingDataInput: false
            });
          })
          .catch((err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message,
              });
            }

            this.setState({
              lockButton: false,
              loadingDataInput: false
            });
          });
      }
    } else if (savedData.dataType === "SETTLE_AGENT") {
      const isSettleAgentFormValid = settleAgentForm.isFormValid();
      this.setState({
        saveClicked: true,
        settleAgentForm,
        lockButton: isSettleAgentFormValid,
        loadingDataInput: isSettleAgentFormValid
      });

      if (isSettleAgentFormValid) {
        const data = {
          date: savedData.date.format("DD.MM.yyyy HH:mm"),
          value: settleAgentForm.amount,
          agentId: savedData.agent,
          notes: settleAgentForm.notes,
          currency: "USD",
        };

        crudActions
          .post(
            `v1/settlement/agent`,
            data,
          )
          .then(() => {
            this.setState({
              controls: Object.assign(controls, {
                merchant: null,
                psp: null,
                midIds: null,
                dataType: null,
              }),
              settleAgentForm: Object.assign(settleAgentForm, {
                amount: "",
                notes: ""
              }),
              savedData: {},
              showAlert: true,
              alertType: "success",
              alertMessage: "Operation successfull.",
              lockButton: false,
              loadingDataInput: false
            });
          })
          .catch((err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message,
              });
            }

            this.setState({
              lockButton: false,
              loadingDataInput: false
            });
          });
      }
    }
  };

  isDateValid = (current) => {
    const { dateNow, lastSettlementDate, controls } = this.state;

    if ( lastSettlementDate && controls.dataType === "SETTLEMENT_BY_DATE") {
      return current.isBetween(lastSettlementDate, dateNow, undefined, '[]');
    }

    return current.isBefore(dateNow);
  };

  getTXValue = (midId) => {
    const { controls, mids } = this.state;
    const mid = mids.find(elem => elem.value === midId);
    const client = mid && mid.clients ? mid.clients.find(item => item.clientId === parseInt(controls.merchant)) : undefined;
    return client ? client.tx : '';
  };

  getValue = (savedValue, formValue) => {
    const isEmptySavedValue = !savedValue && savedValue !== 0;
    const isEmptyFormValue = !formValue && formValue !== 0;

    if (isEmptySavedValue) {
      return formValue;
    } else if (isEmptyFormValue) {
      return savedValue;
    }

    return "";
  };

  filterFields = (rowArray, type, element) => {
    const state = this.state;

    let finishedArray = [];

    if (type === "UPDATE_SETTLEMENT") {
      finishedArray = rowArray.map((elem) => {
        if (elem.display && !element[elem.name]) {
          return {};
        }

        if (element["toDate"] && element["fromDate"] && elem.name === "date") {
          return {};
        }

        return elem;
      });
    } else {
      finishedArray = rowArray.map((elem) => {
        if (elem.display && !state[elem.display]) {
          return {};
        }

        return elem;
      });
    }

    finishedArray.reverse();

    return finishedArray.reduce((accum, currVal) => {
      if (!currVal.fieldType) {
        accum.push(currVal);
      } else {
        accum.unshift(currVal);
      }

      return accum;
    }, []);
  };

  getUniqueData = () => {
    const { settlementDates } = this.state;

    if (!settlementDates) {
      return null;
    }

    const uniqueObjects = Array.from(new Set(settlementDates.map(obj => obj.value)))
      .map(value => {
        return settlementDates.find(obj => obj.value === value);
      });
    return uniqueObjects;
  };

  getMidsOptions = () => {
    const { controls } = this.state;
    if (controls.dataType === "SETTLEMENT_BY_REQUEST" ||
        controls.dataType === "UPDATE_SETTLEMENT") {
      return this.filterMidsByCurrency();
    } else if (
      controls.dataType === "SETTLEMENT_BY_AMOUNT" ||
      controls.dataType === "SETTLEMENT_BY_DATE"
    ) {
      return this.filterMidsByPSPAndCurrency();
    } else {
      return this.filterMids();
    }
  };

  getFilteredStatuses = (items, statusId) => {
    // id 18 - paid
    if (statusId === "18") {
      return items.filter(item => item.label === "Declined");
    }
    return items;
  };

  filterMidsByPSPAndCurrency = () => {
    const { controls, mids } = this.state;
    if (!controls.psp || !controls.merchant) {
      return [];
    }

    return mids.filter(
      (mid) =>
        mid.clients.some(
          (client) => client.clientId === parseInt(controls.merchant, 10)
        ) && controls["psp"].includes(mid.pspId.toString())
    ).filter(
      (mid) =>
        mid.clients.some(
          (client) => client.clientId === parseInt(controls.merchant, 10)
        ) && mid.currency === controls.currency
    );
  };

  filterDataTypes = () => {
    const { companiesClients, controls, dataTypes } = this.state;
    if (controls.dataType === 'SETTLE_AGENT') {
      return dataTypes;
    }
    const selectedClient = companiesClients[controls.company].find(item => item.value === controls.merchant);
    if (selectedClient) {
      return dataTypes.filter(type => type.supportedBy.includes(selectedClient.applicationType));
    }
    return [];
  };

  render() {
    const {
      controls,
      loadingLookups,
      companiesBaseCurrencies,
      companiesClients,
      companiesPsps,
      companies,
      showAlert,
      alertType,
      alertMessage,
      lockButton,
      date,
      notes,
      showSecondPanel,
      runningBalanceTypes,
      runningBalanceValues,
      savedData,
      savedDataList,
      settlementMids,
      settlementStatuses,
      loadingDataInput,
      withheldFunds
    } = this.state;

    const selectedForm = this.state[this.formsMap[savedData.dataType]];

    return (
      <Row flexGrow={ 1 } className="module apidata settlements" vertical="start">
        <Column flexGrow={ 1 }>
          <PageHeader
            title={ "Data Input" }
            lastUpdate={ this.state.lastUpdate }
            img={ ManualInputIcon }
          />
          <Row flexGrow={ 1 } horizontal="start" wrap={ true } vertical="start">
            <Column
              flexGrow={ 1 }
              vertical="start"
              style={ {
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 15,
                width: "100%",
              } }
            >
              <Panel>
                <Panel.Heading>
                  <Panel.Title>SELECT</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  {loadingLookups ? (
                    <div
                      style={ {
                        width: "100%",
                        height: "400px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      } }
                    >
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div
                      className="panel-content"
                      style={ { overflow: "unset" } }
                    >
                      <Row
                        flexGrow={ 1 }
                        wrap={ true }
                        horizontal="start"
                        vertical="end"
                        style={ { width: "100%" } }
                      >
                        {/* WHITE LABEL */}

                        <Column
                          flexGrow={ 1 }
                          vertical="start"
                          alignSelf="start"
                          className="input-column"
                        >
                          <label> White Label </label>
                          <Select
                            id="company"
                            name="company"
                            value={ controls.company || "" }
                            className={
                              controls.errors.has("company")
                                ? "error-field"
                                : ""
                            }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) =>
                              this.onValueChange(value, "company")
                            }
                            options={ companies }
                          />
                        </Column>

                        {/* MERCHANT */ }

                        { controls.dataType !== 'SETTLE_AGENT' && (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Merchant </label>
                            <Select
                              id="merchant"
                              name="merchant"
                              value={ controls.merchant || "" }
                              className={
                                controls.errors.has("merchant")
                                  ? "error-field"
                                  : ""
                              }
                              required={ true }
                              clearable={ false }
                              disabled={ !controls.company }
                              onChange={ (value) =>
                                this.onValueChange(value, "merchant")
                              }
                              options={
                                controls.company
                                  ? companiesClients[controls.company]
                                  : []
                              }
                            />
                          </Column>
                        ) }

                        {/* DATA TYPE */}

                        <Column
                          flexGrow={ 1 }
                          vertical="start"
                          alignSelf="start"
                          className="input-column"
                        >
                          <label> Data Type </label>
                          <Select
                            id="dataType"
                            name="dataType"
                            value={ controls.dataType || "" }
                            className={
                              controls.errors.has("dataType")
                                ? "error-field"
                                : ""
                            }
                            required={ true }
                            clearable={ false }
                            disabled={ !controls.company || (!controls.merchant && controls.dataType !== 'SETTLE_AGENT') }
                            onChange={ (value) =>
                              this.onValueChange(value, "dataType")
                            }
                            options={ (controls.merchant || controls.dataType === 'SETTLE_AGENT') ? this.filterDataTypes() : [] }
                          />
                        </Column>

                        {/* AGENTS */ }

                        { controls.dataType === 'SETTLE_AGENT' && <Column
                          flexGrow={ 1 }
                          vertical="start"
                          alignSelf="start"
                          className="input-column"
                        >
                          <label> Agents </label>
                          <Select
                            id="agentId"
                            name="agentId"
                            value={ controls.agent || "" }
                            className={
                              controls.errors.has("agent")
                                ? "error-field"
                                : ""
                            }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) =>
                              this.onValueChange(value, "agent")
                            }
                            options={ this.state.agents }
                          />
                        </Column>
                        }

                        {/* DATE or STATUS */ }

                        { controls.dataType === "SETTLEMENT_BY_DATE" || controls.dataType === "SETTLE_AGENT" ? (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Date </label>
                            <Datetime
                              type="text"
                              timeFormat={ controls.dataType === 'SETTLE_AGENT' && "HH:mm" }
                              inputProps={ {
                                onKeyDown: (e) => {
                                  e.preventDefault();
                                },
                              } }
                              dateFormat="DD.MM.YYYY"
                              value={ controls.date || "" }
                              isValidDate={ (event) => this.isDateValid(event) }
                              onChange={ (event) => this.onValueChange({ value: Datetime.moment(event) }, "date") }
                              required={ true }
                            />
                          </Column>
                        ) : controls.dataType === "UPDATE_SETTLEMENT" ? (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Status </label>
                            <Select
                              id="settlementStatus"
                              name="settlementStatus"
                              value={ controls.settlementStatus || "" }
                              className={
                                controls.errors.has("settlementStatus")
                                  ? "error-field"
                                  : ""
                              }
                              required={ true }
                              clearable={ false }
                              onChange={ (value) =>
                                this.onValueChange(value, "settlementStatus")
                              }
                              options={ settlementStatuses.filter(
                                (elem) =>
                                  elem.label === "Requested" ||
                                  elem.label === "Paid" ||
                                  elem.label === "In Progress"
                              ) }
                            />
                          </Column>
                        ) : (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <span />
                          </Column>
                        )}
                      </Row>
                      <Row
                        flexGrow={ 1 }
                        wrap={ true }
                        horizontal="start"
                        vertical="end"
                        style={ { width: "100%" } }
                      >
                        {/* CURRENCY (optional) */}

                        {(controls.dataType !== "" &&
                          controls.dataType !== "RUNNING_BALANCE" &&
                          controls.dataType !== "TOP_UP_PAYOUT_WALLET" &&
                          controls.dataType !== "WITHHELD_FUNDS" &&
                          controls.dataType !== "SETTLE_AGENT") && (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Currency </label>
                            <Select
                              id="currency"
                              name="currency"
                              value={ controls.currency || "" }
                              className={
                                controls.errors.has("currency")
                                  ? "error-field"
                                  : ""
                              }
                              required={ true }
                              clearable={ false }
                              onChange={ (value) =>
                                this.onValueChange(value, "currency")
                              }
                              options={
                                companiesBaseCurrencies[controls.company]
                              }
                            />
                          </Column>
                        )}

                        {/* PSP */}

                        {(controls.dataType === "SETTLEMENT_BY_AMOUNT" ||
                          controls.dataType === "SETTLEMENT_BY_DATE") && (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Provider </label>
                            <Multiselect
                              id="psp"
                              name="psp"
                              isError={ controls.errors.has("psp") }
                              selectedItems={ this.mapSelectedItems(
                                "psp",
                                controls.company
                              ) }
                              className={
                                controls.errors.has("psp") ? "error-field" : ""
                              }
                              required={ true }
                              clearable={ false }
                              disabled={ !controls.company }
                              onChange={ (value) =>
                                this.onValueChange(value, "psp")
                              }
                              items={
                                controls.company
                                  ? companiesPsps[controls.company]
                                  : []
                              }
                            />
                          </Column>
                        )}

                        {(controls.dataType === "" ||
                          controls.dataType === "RUNNING_BALANCE" ||
                          controls.dataType === "TOP_UP_PAYOUT_WALLET" ||
                          controls.dataType === "WITHHELD_FUNDS") && (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Provider </label>
                            <Select
                              id="psp"
                              name="psp"
                              value={ controls.psp || "" }
                              className={
                                controls.errors.has("psp")
                                  ? "error-field"
                                  : ""
                              }
                              required={ true }
                              clearable={ false }
                              disabled={ !controls.company }
                              onChange={ (value) =>
                                this.onValueChange(value, "psp")
                              }
                              options={
                                controls.company
                                  ? companiesPsps[controls.company]
                                  : []
                              }
                            />
                          </Column>
                        )}

                        {/* MID */}

                        { controls.dataType !== "SETTLE_AGENT" && <Column
                          flexGrow={ 1 }
                          vertical="start"
                          alignSelf="start"
                          className="input-column"
                        >
                          <label> MID </label>
                          {(controls.dataType !== "" &&
                            controls.dataType !== "RUNNING_BALANCE" &&
                            controls.dataType !== "TOP_UP_PAYOUT_WALLET" &&
                            controls.dataType !== "WITHHELD_FUNDS") ? (
                              <Multiselect
                                isError={ controls.errors.has("midIds") }
                                selectedItems={ this.mapSelectedMids("midIds") }
                                className={
                                  controls.errors.has("midIds")
                                    ? "error-field"
                                    : ""
                                }
                                disabled={
                                  (controls.dataType !== "" &&
                                    controls.dataType !== "RUNNING_BALANCE" &&
                                    controls.dataType !== "TOP_UP_PAYOUT_WALLET")
                                    ? !controls.company ||
                                    !controls.merchant ||
                                    !controls.currency
                                    : !controls.company ||
                                    !controls.merchant ||
                                    !controls.psp
                                }
                                items={ this.getMidsOptions() }
                                onChange={ (value) =>
                                  this.onValueChange(value, "midIds")
                                }
                              />
                            ) : (
                              <Select
                                id="midIds"
                                name="midIds"
                                value={ controls.midIds || "" }
                                className={
                                  controls.errors.has("midIds") ? "error-field" : ""
                                }
                                required={ true }
                                clearable={ false }
                                disabled={
                                  !controls.company ||
                                !controls.merchant ||
                                !controls.psp
                                }
                                onChange={ (value) =>
                                  this.onValueChange(value, "midIds")
                                }
                                options={ this.filterMids() }
                              />
                            )}
                        </Column>
                        }

                        {/* DATE (optional) */}

                        { controls.dataType !== "SETTLEMENT_BY_AMOUNT" &&
                          controls.dataType !== "SETTLEMENT_BY_DATE" &&
                          controls.dataType !== "SETTLE_AGENT" && (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Date </label>
                            {controls.dataType === "UPDATE_SETTLEMENT" || controls.dataType === "SETTLEMENT_BY_REQUEST" ? (
                              <Select
                                id="date"
                                name="date"
                                value={ controls.updateSettlementDate || "" }
                                className={
                                  controls.errors.has("updateSettlementDate")
                                    ? "error-field"
                                    : ""
                                }
                                required={ true }
                                clearable={ false }
                                disabled={
                                  controls.dataType === "SETTLEMENT_BY_REQUEST"
                                    ? (!controls.company ||
                                    !controls.merchant ||
                                    !controls.currency ||
                                    !controls.midIds.length)
                                    : (!controls.company ||
                                    !controls.merchant ||
                                    !controls.settlementStatus ||
                                    !controls.currency ||
                                    !controls.midIds.length)
                                }
                                onChange={ (value) =>
                                  this.onValueChange(
                                    value,
                                    "updateSettlementDate"
                                  )
                                }
                                options={ this.getUniqueData() }
                              />
                            ) : (
                              <Datetime
                                type="text"
                                timeFormat={ false }
                                inputProps={ {
                                  onKeyDown: (e) => {
                                    e.preventDefault();
                                  },
                                  disabled: controls.dataType === "TOP_UP_PAYOUT_WALLET"
                                } }
                                dateFormat="DD.MM.YYYY"
                                value={ date || "" }
                                isValidDate={ (event) => this.isDateValid(event) }
                                onChange={ (event) =>
                                  this.handleDateChange(event)
                                }
                                required={ true }
                              />
                            )}
                          </Column>
                        ) }
                        { controls.dataType === "SETTLE_AGENT" && (
                          <>
                            <Column
                              flexGrow={ 1 }
                              className="input-column empty-column">
                              <span/>
                            </Column>
                            <Column
                              flexGrow={ 1 }
                              className="input-column empty-column">
                              <span/>
                            </Column>
                            <Column
                              flexGrow={ 1 }
                              className="input-column empty-column">
                              <span/>
                            </Column>
                          </>
                        )}

                        <Column
                          flexGrow={ 1 }
                          className="input-column fullwidth-button"
                        >
                          <CustomButton
                            title="Filter"
                            type="button"
                            disabled={ lockButton }
                            onClick={ (e) => this.onChangeFilter() }
                          />
                        </Column>
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel>
              {loadingDataInput && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>DATA ENTRIES</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div
                      style={ {
                        width: "100%",
                        height: "400px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      } }
                    >
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              )}
              {(showSecondPanel && !loadingDataInput && savedData.dataType === "RUNNING_BALANCE") && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>DATA ENTRIES</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div
                      className="panel-content rb-form"
                      style={ { overflow: "unset" } }
                    >
                      <Row
                        wrap={ true }
                        horizontal="start"
                        vertical="end"
                        className="running-balance-form"
                      >
                        <ManualDataInput
                          items={ runningBalanceTypes }
                          editable={ true }
                          selectedItems={ runningBalanceValues }
                          onTemporaryChange={ this.onTypesTemporaryChange }
                          onChange={ this.onTypesChange }
                        />
                          
                      </Row>
                      {this.checkPageAccess("DATA_INPUT_WRITE") && (
                        <Row
                          flexGrow={ 1 }
                          wrap={ true }
                          horizontal="end"
                          vertical="end"
                          style={ { width: "100%" } }
                        >
                          <Column className="input-column fullwidth-button">
                            <CustomButton
                              title="Save"
                              type="button"
                              onClick={ (e) => this.onSaveDataEntries() }
                            />
                          </Column>
                        </Row>
                      )}
                    </div>
                  </Panel.Body>
                </Panel>
              )}

              {(showSecondPanel && !loadingDataInput && savedData.dataType === "WITHHELD_FUNDS") && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>DATA ENTRIES</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div
                      className="panel-content withheld-form"
                      style={ { overflow: "unset" } }
                    >
                      <Row
                        wrap={ true }
                        horizontal="start"
                        vertical="end"
                        className="running-balance-form"
                      >
                        <WithheldFunds
                          editable={ true }
                          savedData={ savedData }
                          funds={ withheldFunds }
                          onTemporaryChange={ this.onWithheldTemporaryChange }
                          onChange={ this.onWithheldChange }
                        />
                          
                      </Row>
                      {this.checkPageAccess("DATA_INPUT_WRITE") && (
                        <Row
                          flexGrow={ 1 }
                          wrap={ true }
                          horizontal="end"
                          vertical="end"
                          style={ { width: "100%" } }
                        >
                          <Column className="input-column fullwidth-button">
                            <CustomButton
                              title="Save"
                              type="button"
                              onClick={ (e) => this.onSaveDataEntries() }
                            />
                          </Column>
                        </Row>
                      )}
                    </div>
                  </Panel.Body>
                </Panel>
              )}

              {showSecondPanel && !loadingDataInput && savedData.dataType !== "RUNNING_BALANCE" &&
                savedData.dataType !== "UPDATE_SETTLEMENT" && savedData.dataType !== "WITHHELD_FUNDS" && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>DATA ENTRIES</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div
                      className={ `panel-content amount-form` }
                      style={ { overflow: "unset" } }
                    >
                      {(savedData.dataType === "SETTLEMENT_BY_AMOUNT" ||
                        savedData.dataType === "SETTLEMENT_BY_DATE" ||
                        savedData.dataType === "SETTLEMENT_BY_REQUEST") && (
                        <>
                          {settlementMids.map((mid, index) => (
                            <Row
                              key={ index }
                              wrap={ true }
                              horizontal="start"
                              vertical="center"
                              style={ { width: "100%" } }
                              className="mid-row"
                            >
                              <Column flexGrow="0.5" className="input-column">
                                <label>MID</label>
                                <input
                                  id={ `${mid.id}-${mid.name}` }
                                  name="name"
                                  type="text"
                                  value={ mid.name }
                                  className="form-control"
                                  disabled={ true }
                                  onChange={ (value) =>
                                    this.onMidRowChange(value, "name")
                                  }
                                />
                              </Column>

                              <Column flexGrow="0.25" className="input-column">
                                <label>Provider</label>
                                <input
                                  id={ `${mid.id}-${mid.name}` }
                                  name="providerName"
                                  type="text"
                                  value={ mid.providerName }
                                  className="form-control"
                                  disabled={ true }
                                  onChange={ (value) =>
                                    this.onMidRowChange(value, "providerName")
                                  }
                                />
                              </Column>

                              {savedData.dataType === "SETTLEMENT_BY_DATE" && (
                                <>
                                  <Column flexGrow={ 0 } className="input-column">
                                    <label>From Date</label>
                                    <input
                                      id={ `${mid.id}-${mid.name}` }
                                      name="fromDate"
                                      type="text"
                                      value={ mid.fromDate }
                                      className="form-control"
                                      disabled={ true }
                                      onChange={ (value) =>
                                        this.onSettlementChange(value, "fromDate")
                                      }
                                    />
                                  </Column>

                                  <Column flexGrow="0.5" className="input-column">
                                    <label>
                                      To Date{' '}<span className="txValue">(T+{this.getTXValue(mid.id)})</span>
                                    </label>
                                    <input
                                      id={ `${mid.id}-${mid.name}` }
                                      name="toDate"
                                      type="text"
                                      value={ mid.toDate }
                                      className="form-control"
                                      disabled={ true }
                                      onChange={ (value) =>
                                        this.onSettlementChange(value, "toDate")
                                      }
                                    />
                                    <span className="txValue-inputMask">(T+{this.getTXValue(mid.id)})</span>
                                  </Column>
                                </>
                              )}

                              {savedData.dataType === "SETTLEMENT_BY_AMOUNT" && (
                                <Column flexGrow="0.5" className="input-column">
                                  <label>
                                    Date{' '}<span className="txValue">(T+{this.getTXValue(mid.id)})</span>
                                  </label>
                                  <input
                                    id={ `${mid.id}-${mid.name}` }
                                    name="date"
                                    type="text"
                                    value={ mid.date }
                                    className="form-control"
                                    disabled={ true }
                                    onChange={ (value) =>
                                      this.onSettlementChange(value, "date")
                                    }
                                  />
                                  <span className="txValue-inputMask">(T+{this.getTXValue(mid.id)})</span>
                                </Column>
                              )}

                              {savedData.dataType === "SETTLEMENT_BY_AMOUNT" && (
                                <Column flexGrow="0.25" className="input-column">
                                  <label>
                                    Withheld Funds
                                  </label>
                                  <input
                                    id={ `${mid.id}-${mid.name}` }
                                    name="withheldFunds"
                                    type="text"
                                    value={ mid.withheldFunds }
                                    className="form-control"
                                    disabled={ true }
                                    onChange={ (value) =>
                                      this.onSettlementChange(value, "withheldFunds")
                                    }
                                  />
                                </Column>
                              )}
                              
                              {savedData.dataType !== "SETTLEMENT_BY_DATE" && (
                                <Column
                                  flexGrow="0.25"
                                  className="input-column"
                                >
                                  <label>Payout Wallet</label>
                                  <input
                                    id={ `${mid.id}-${mid.name}` }
                                    name="payoutWalletBalance"
                                    type="text"
                                    value={ mid.payoutWalletBalance }
                                    className="form-control"
                                    disabled={ true }
                                    onChange={ (value) =>
                                      this.onSettlementChange(
                                        value,
                                        "payoutWalletBalance"
                                      )
                                    }
                                  />
                                  {mid.payoutWalletType !== "" && (
                                    <span className={ `txValue-inputMask ${mid.payoutWalletType === "N/A" ? 'full-width' : ''}` }>
                                      { `(${mid.payoutWalletType})` }
                                    </span>
                                  )}
                                </Column>
                              )}

                              <Column flexGrow="0.25" className="input-column">
                                <label>Pending Payouts</label>
                                <input
                                  id={ `${mid.id}-${mid.name}` }
                                  name="pendingPayouts"
                                  type="text"
                                  value={ mid.pendingPayouts }
                                  className="form-control"
                                  disabled={ true }
                                  onChange={ (value) =>
                                    this.onMidRowChange(
                                      value,
                                      "pendingPayouts"
                                    )
                                  }
                                />
                              </Column>

                              <Column flexGrow="0.25" className="input-column">
                                <label>Available Balance</label>
                                <input
                                  id={ `${mid.id}-${mid.name}` }
                                  name="availableBalance"
                                  type="text"
                                  value={ mid.availableBalance }
                                  className="form-control"
                                  disabled={ true }
                                  onChange={ (value) =>
                                    this.onMidRowChange(
                                      value,
                                      "availableBalance"
                                    )
                                  }
                                />
                              </Column>

                              <Column flexGrow="0.25" className="input-column">
                                <label>Balance to Settle</label>
                                <input
                                  id={ `${mid.id}-${mid.name}` }
                                  name="balanceToSettle"
                                  type="text"
                                  value={ mid.balanceToSettle }
                                  className="form-control"
                                  disabled={ savedData.dataType !== "SETTLEMENT_BY_AMOUNT" }
                                  onChange={ (value) =>
                                    this.onMidRowChange(
                                      value,
                                      "balanceToSettle",
                                      index
                                    )
                                  }
                                />
                              </Column>
                              
                              <Column
                                flexGrow={ 0 }
                                className="input-column checkbox-column"
                              >
                                <label
                                  className="container"
                                  style={ { width: "20px", marginTop: "10px" } }
                                >
                                  <input
                                    type="checkbox"
                                    disabled={ mid.disabled }
                                    checked={ mid.isChecked }
                                    onChange={ () => this.onMidSelect(index, savedData.dataType) }
                                  />
                                  <span className="checkmark" disabled={ mid.disabled }></span>
                                </label>
                              </Column>
                            </Row>
                          ))}
                          <hr />
                        </>
                      )}

                      { (savedData.dataType === "SETTLEMENT_BY_AMOUNT" ||
                            savedData.dataType === "SETTLEMENT_BY_DATE" ||
                            savedData.dataType === "SETTLEMENT_BY_REQUEST" ||
                            savedData.dataType === "UPDATE_SETTLEMENT" ||
                            savedData.dataType === "TOP_UP_PAYOUT_WALLET" ||
                            savedData.dataType === "SETTLE_AGENT") &&
                          this.fieldsMap[savedData.dataType].map(
                            (rowArray, index) => (
                              <Row
                                key={ index }
                                wrap={ true }
                                horizontal="start"
                                vertical="end"
                                style={ { width: savedData.dataType === "TOP_UP_PAYOUT_WALLET" || savedData.dataType === "SETTLE_AGENT" ? "70%" : "100%" } }
                              >
                                {this.filterFields(rowArray).map(
                                  (fieldElem, elemIndex) => (
                                    <Column
                                      key={ `${fieldElem.name}-${elemIndex}` }
                                      flexGrow={
                                        fieldElem.flexGrow !== undefined
                                          ? parseInt(fieldElem.flexGrow)
                                          : 1
                                      }
                                      className="input-column"
                                    >
                                      <label> {fieldElem.label} </label>
                                      {fieldElem.fieldType === "date" && (
                                        <img
                                          src={ Calendar }
                                          className="calendar-svg"
                                          alt="calendar"
                                        />
                                      )}
                                      {fieldElem.fieldType === "select" ? (
                                        <Select
                                          id={ fieldElem.name }
                                          name={ fieldElem.name }
                                          value={
                                            savedData[fieldElem.name] ||
                                            selectedForm[fieldElem.name] ||
                                            ""
                                          }
                                          className={
                                            selectedForm.errors.has(
                                              fieldElem.name
                                            )
                                              ? "error-field"
                                              : ""
                                          }
                                          required={ true }
                                          clearable={ false }
                                          disabled={ fieldElem.disabled }
                                          onChange={ (value) =>
                                            this.onSettlementChange(
                                              value,
                                              fieldElem.name,
                                              this.formsMap[savedData.dataType]
                                            )
                                          }
                                          options={
                                            savedData[fieldElem.name] ||
                                            this.state[fieldElem.items]
                                          }
                                        />
                                      ) : !fieldElem.fieldType ? (
                                        <span/>
                                      ) : fieldElem.fieldType === 'textarea' ? (
                                        <TextareaAutosize
                                          cacheMeasurements
                                          value={ this.getValue(
                                            savedData[fieldElem.name],
                                            selectedForm[fieldElem.name]
                                          ) }
                                          onChange={ (value) =>
                                            this.onSettlementChange(
                                              value,
                                              fieldElem.name,
                                              this.formsMap[savedData.dataType]
                                            ) }
                                          className="form-control"
                                        />
                                      ) : (
                                        <input
                                          id={ fieldElem.name }
                                          name={ fieldElem.name }
                                          type={ fieldElem.inputType || "text" }
                                          value={ this.getValue(
                                            savedData[fieldElem.name],
                                            selectedForm[fieldElem.name]
                                          ) }
                                          className={ `form-control ${
                                            selectedForm.errors.has(
                                              fieldElem.name
                                            )
                                              ? "error-field"
                                              : ""
                                          }` }
                                          disabled={ fieldElem.disabled }
                                          style={ {
                                            paddingLeft:
                                              fieldElem.fieldType === "date"
                                                ? 35
                                                : 10,
                                          } }
                                          onChange={ (value) =>
                                            this.onSettlementChange(
                                              value,
                                              fieldElem.name,
                                              this.formsMap[savedData.dataType]
                                            )
                                          }
                                        />
                                      )}
                                    </Column>
                                  )
                                )}
                              </Row>
                            )
                          )}
                      
                      {this.checkPageAccess("DATA_INPUT_WRITE") && (
                        <Row
                          wrap={ true }
                          horizontal="start"
                          vertical="end"
                          style={ { width: "100%" } }
                        >
                          <Column
                            flexGrow={ 1 }
                            className="input-column empty-column"
                          >
                            <span />
                          </Column>
                          <Column
                            flexGrow={ 1 }
                            className="input-column empty-column"
                          >
                            <span />
                          </Column>
                          <Column
                            flexGrow={ 1 }
                            className="input-column empty-column"
                          >
                            <span />
                          </Column>
                          <Column
                            flexGrow={ 1 }
                            className="input-column"
                          >
                            { savedData.dataType === "SETTLEMENT_BY_DATE" || savedData.dataType === "SETTLEMENT_BY_AMOUNT" || savedData.dataType === "SETTLEMENT_BY_REQUEST" ? (
                              <>
                                <label> Notes </label>
                                <TextareaAutosize
                                  cacheMeasurements
                                  value={ notes || "" }
                                  onChange={ (e) => this.setState({ notes: e.target.value }) }
                                  className="form-control"
                                />
                              </>
                            ) : (
                              <span/>
                            )}
                          </Column>
                          <Column
                            flexGrow={ 1 }
                            className="input-column fullwidth-button"
                          >
                            <CustomButton
                              title={ controls.dataType !== "SETTLEMENT_BY_AMOUNT" ? 'Save' : 'Submit' }
                              type="button"
                              disabled={ lockButton || (controls.dataType === "TOP_UP_PAYOUT_WALLET" && savedData.topUpRecordId !== undefined) }
                              onClick={ (e) => this.onSaveDataEntries() }
                            />
                          </Column>
                        </Row>
                      )}
                    </div>
                  </Panel.Body>
                </Panel>
              )}
              {showSecondPanel && savedData.dataType === "UPDATE_SETTLEMENT" && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>DATA ENTRIES</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div
                      className="panel-content amount-form"
                      style={ { overflow: "unset" } }
                    >
                      {savedDataList.map((elem, i) => (
                        <Row
                          key={ i }
                          wrap={ true }
                          horizontal="start"
                          vertical="end"
                          style={ { width: "100%" } }
                        >
                          {this.fieldsMap[savedData.dataType].map(
                            (rowArray, index) => (
                              <Row
                                key={ index }
                                wrap={ true }
                                horizontal="start"
                                vertical="end"
                                style={ { width: "100%" } }
                              >
                                {this.filterFields(
                                  rowArray,
                                  savedData.dataType,
                                  elem
                                ).map((fieldElem, elemIndex) => (
                                  <Column
                                    key={ `${fieldElem.name}-${elemIndex}` }
                                    flexGrow={ 1 }
                                    className="input-column"
                                  >
                                    <label> {fieldElem.label} </label>
                                    {fieldElem.fieldType === "select" ? (
                                      <Select
                                        id={ fieldElem.name }
                                        name={ fieldElem.name }
                                        value={
                                          elem[fieldElem.name] ||
                                          controls.settlementStatus ||
                                          ""
                                        }
                                        required={ true }
                                        clearable={ false }
                                        disabled={ fieldElem.disabled }
                                        onChange={ (value) =>
                                          this.onSettlementChange(
                                            value,
                                            fieldElem.name,
                                            this.formsMap[savedData.dataType],
                                            elem.id
                                          )
                                        }
                                        options={ this.getFilteredStatuses(elem[fieldElem.items] || this.state[fieldElem.items], savedData.settlementStatus) }
                                      />
                                    ) : !fieldElem.fieldType ? (
                                      <span />
                                    ) : (
                                      <input
                                        id={ fieldElem.name }
                                        name={ fieldElem.name }
                                        type="text"
                                        value={ this.getValue(
                                          elem[fieldElem.name],
                                          selectedForm[fieldElem.name]
                                        ) }
                                        className={ `form-control ${
                                          selectedForm.errors.has(
                                            fieldElem.name
                                          )
                                            ? "error-field"
                                            : ""
                                        }` }
                                        disabled={ fieldElem.disabled }
                                        style={ { paddingLeft: 10 } }
                                        onChange={ (value) =>
                                          this.onSettlementChange(
                                            value,
                                            fieldElem.name,
                                            this.formsMap[savedData.dataType]
                                          )
                                        }
                                      />
                                    )}
                                  </Column>
                                ))}
                              </Row>
                            )
                          )}
                        </Row>
                      ))}
                      {this.checkPageAccess("DATA_INPUT_WRITE") && (
                        <Row
                          wrap={ true }
                          horizontal="start"
                          vertical="end"
                          style={ { width: "100%" } }
                        >
                          <Column
                            flexGrow={ 1 }
                            className="input-column empty-column"
                          >
                            <span />
                          </Column>
                          <Column
                            flexGrow={ 1 }
                            className="input-column empty-column"
                          >
                            <span />
                          </Column>
                          <Column
                            flexGrow={ 1 }
                            className="input-column empty-column"
                          >
                            <span />
                          </Column>
                          <Column
                            flexGrow={ 1 }
                            className="input-column fullwidth-button"
                          >
                            <CustomButton
                              title="Save"
                              type="button"
                              disabled={ lockButton }
                              onClick={ (e) => this.onSaveDataEntries() }
                            />
                          </Column>
                        </Row>
                      )}
                    </div>
                  </Panel.Body>
                </Panel>
              )}
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>
    );
  }
}

export default ManualInput;
