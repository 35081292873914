import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import Form from "@sm/core/Form";
import Multiselect from '@sm/components/custom/customMultiselect';
import Select from "@sm/components/custom/Select";
import Spinner from '@sm/components/Spinner';

import '@assets/css/userManagement.css';
import UserManagementIcon from '@assets/images/user-management.png';

import localization from '@assets/lang/language';
import { crudActions } from "@sm/services/crudActions";
import { FETCH_PERMISSIONS } from '@sm/actions/types';
const store =  require('@sm/reducers/index');

class AgentManagement extends Component {
  state = {
    filterOn: false,

    companies: [],
    userStatus: [],
    filteredUsers: [],
    selectedCompany: "",

    controls: new Form({
      companyIds: "",
      agentsStatus: [],
      agentTypes: [],
      name: "",
      email: ""
    }, [{
      name: "agentsStatus",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "agentTypes",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "name",
      type: "isString",
      rules: {
        required: false,
      }
    }, {
      name: "email",
      type: "isString",
      rules: {
        required: false,
        min: 1
      }
    }]),

    access: [],

    showAlert: false,
    alertType: "success",
    alertMessage: "",
    usersLoading: false,
    loadingLookups: true,
    searchClicked: false,

    //temporary
    currentLanguage: "en"
  };

  subscribeFunction = null;

  componentDidMount() {
    const { controls } = this.state;
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    Promise.all([
      crudActions.get('v1/users/management/lookups'),
      crudActions.get(`v1/companies/labels`)
    ]).then(
      (response) => {
        const lookups = response[0];
        const companiesData = response[1];

        if (lookups && companiesData) {
          const agentTypes = lookups.agentTypes.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));
          const userStatus = lookups.userStatus;

          const updateObject = {
            agentTypes: agentTypes,
            userStatus: userStatus,
            companies: companiesData,
            loadingLookups: false
          };

          const firstCompany = companiesData[0].value;
          updateObject.selectedCompany = firstCompany;

          updateObject.controls = Object.assign(controls, {
            agentsStatus: [userStatus.find(elem => elem.value === "ACTIVE").value],
            agentTypes: agentTypes.map(elem => { return elem.value; })
          });

          this.setState(updateObject, () => {
            if (storeState.roleId && (storeState.roleId === "MASTER_TECH" || storeState.roleId === "MASTER_ADMIN" || 
              storeState.roleId === "MASTER_USER" )) {
              this.onValueChange(companiesData[0], "companyIds");
            }
          });
        }
      }
    );
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  onValueChange = (event, field) => {
    const { controls, searchClicked } = this.state;
    if (!event) {
      controls[field] = event;
    } else if (event.target) {
      controls[field] = event.target.value;
    } else if (event.value) {
      controls[field] = event.value;
    } else {
      controls[field] = event.map(elem => elem.value);
    }

    if (searchClicked) {
      controls.isFormValid();
    }

    this.setState({
      controls,
      ...(field === "companyIds" && { selectedCompany: event.value })
    });
  };

  onChangeFilter = () => {
    this.filterAgents();
  };

  filterAgents = () => {
    const { controls, selectedCompany } = this.state;

    const isControlsValid = controls.isFormValid();
    this.setState({
      controls,
      usersLoading: isControlsValid,
      filterOn: this.state.filterOn ? true : isControlsValid,
      searchClicked: true
    });

    const controlsData = controls.data();
    controlsData.companyIds = [selectedCompany];

    if (isControlsValid) {
      (crudActions.post('v1/agent/find', controlsData)).then(
        (users) => {
          if (users) {
            this.setState({
              filteredUsers: users,
              usersLoading: false
            });
          }
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              usersLoading: false
            });
          }
        }
      );
    }
  };

  mapSelectedItems = (type, lookupsName) => {
    const field = this.state.controls[type];
    const lookup = this.state[lookupsName];
    if (!field || !lookup) {
      return [];
    }

    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  localizeItems = (items, fieldName) => {
    const { currentLanguage } = this.state;
    const language = localization[currentLanguage];

    const field = language[fieldName];
    return items.map(elem => {
      return { ...elem, label: field[elem.label] };
    });
  };

  userActivation = (activateOrDeactivate, userId) => {
    crudActions.put(`v1/users/management/user/${userId}/${activateOrDeactivate ? "reactivate" : "deactivate"}`).then(
      () => {
        this.setState({
          showAlert: true,
          alertType: "success",
          alertMessage: `Agent successfully ${activateOrDeactivate ? "activated." : "deactivated."}`
        });
      }
    ).catch(
      err => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
          });
        }
      }
    );
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    }, this.state.showAlert && this.state.alertType === "success" ? this.filterAgents : null);
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  render() {
    const {
      agentTypes,
      alertType,
      alertMessage,
      companies,
      controls,
      filterOn,
      filteredUsers,
      loadingLookups,
      selectedCompany,
      showAlert,
      usersLoading,
      userStatus
    } = this.state;

    return (
      <Row flexGrow={ 1 } className="module user-management" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ UserManagementIcon } alt="" style={ { marginRight: 10 } }/>
                Agent Management
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                { this.checkPageAccess("USER_EDIT") && <Column horizontal='end' style={ { paddingRight: 15 } }>
                  <Row horizontal='end' vertical='center' style={ { color: '#ccc', fontSize: '12px' } }>
                    <NavLink 
                      to={ {
                        pathname: `/add-user`,
                        state: { params: { isAgent: true } }
                      } }
                      className="btn add-deposit-button"
                    >
                      CREATE NEW AGENT
                    </NavLink>
                  </Row>
                </Column> }
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    AGENT SEARCH
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { loadingLookups ? (
                    <div style={ { width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='center' vertical='center' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> White Label Access </label>
                          <Select
                            id="whiteLabel"
                            name="whiteLabel"
                            value={ selectedCompany || '' }
                            required={ true }
                            clearable={ false }
                            disabled={ companies.length === 1 }
                            onChange={ (value) => this.onValueChange(value, "companyIds") }
                            options={ companies }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <label> Agent Type </label>
                          <Multiselect
                            isError={ controls.errors.has("agentTypes") }
                            selectedItems={ this.mapSelectedItems("agentTypes", "agentTypes") }
                            items={ agentTypes }
                            type={ "agentTypes" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Status </label>
                          <Multiselect
                            isError={ controls.errors.has("agentsStatus") }
                            selectedItems={ this.mapSelectedItems("agentsStatus", "userStatus") }
                            items={ userStatus }
                            type={ "agentsStatus" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Name </label>
                          <input
                            className={ "form-control " + (controls.errors.has("name") ? "error-field" : "") }
                            value={ controls.name || '' }
                            onChange={ (value) => this.onValueChange(value, 'name') }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='center' vertical='center' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Email/Username </label>
                          <input
                            className={ "form-control " + (controls.errors.has("email") ? "error-field" : "") }
                            value={ controls.email || '' }
                            onChange={ (value) => this.onValueChange(value, 'email') }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='end' className="input-column fullwidth-button">
                          <CustomButton
                            title="Search"
                            type="submit"
                            style={ { width: "100%", outline: usersLoading ? "5px auto -webkit-focus-ring-color" : "0" } }
                            onClick={ () => this.onChangeFilter() }
                          />
                        </Column>
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel>
              { filterOn && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      AGENTS
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    { usersLoading ? (
                      <div style={ { width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                        <Spinner smallContainer={ true } />
                      </div>
                    ) : (
                      <div className="panel-content" style={ {overflow: 'unset'} }>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className="user-management-wrapper">
                          <table className="table table-striped user-management-table">
                            <thead>
                              <tr>
                                <th>
                                  Full Name
                                </th>
                                <th>
                                  Email/Username
                                </th>
                                <th>
                                  Agent Type
                                </th>
                                { this.checkPageAccess("USER_VIEW") && <th className="text-center">
                                  View
                                </th> }
                                { this.checkPageAccess("USER_EDIT") && <th className="text-center">
                                  Edit
                                </th> }
                                { this.checkPageAccess("USER_EDIT") && <th className="text-center">
                                  Activation
                                </th> }
                              </tr>
                            </thead>
                            <tbody>
                              {
                                filteredUsers.map((user) => {
                                  return <tr key={ user.id }>
                                    <td>
                                      {user.name}
                                    </td>
                                    <td>
                                      {user.email}
                                    </td>
                                    <td>
                                      {user.type && agentTypes.find(type => type.value === user.type).label}
                                    </td>
                                    { this.checkPageAccess("USER_VIEW") && <td>
                                      <NavLink to={ `/view-user/${user.id}` } className="btn view-user">
                                        View
                                      </NavLink>
                                    </td> }
                                    { this.checkPageAccess("USER_EDIT") && <td>
                                      <NavLink to={ `/edit-user/${user.id}` } className="btn edit-user">
                                        Edit
                                      </NavLink>
                                    </td> }
                                    { this.checkPageAccess("USER_EDIT") && <td>
                                      <Button
                                        className={ `btn ${user.active === "ACTIVE" ? "deactivate-user" : "activate-user"}` }
                                        onClick={ () => this.userActivation(user.active === "DEACTIVATED", user.id) }
                                      >
                                        { user.active === "ACTIVE" ? "Deactivate" : "Reactivate" }
                                      </Button>
                                    </td> }
                                  </tr>;
                                })
                              }
                            </tbody>
                          </table>
                        </Row>
                      </div>
                    )}
                  </Panel.Body>
                </Panel>
              )}
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>

    );
  }
}

export default AgentManagement;
