import { DASHBOARD_SELLS_EVENT, DASHBOARD_PURCHASES_EVENT, DASHBOARD_FTD_EVENT,
  DASHBOARD_TRANSFER_EVENT, DASHBOARD_REVENUE_EVENT, DASHBOARD_USERS_EVENT,
  DASHBOARD_PURCHASE_COUNTRIES_EVENT, DASHBOARD_CUSTOMER_BALANCES_EVENT, DASHBOARD_PIE_EVENT,
  DASHBOARD_HISTOGRAM_EVENT, DASHBOARD_WITHDRAWALS_EVENT, DASHBOARD_SETTLEMENTS, 
  DASHBOARD_PAYOUT_COUNTRIES_EVENT, DASHBOARD_PAYOUT_EVENT, DASHBOARD_DEPOSITS_PER_MIDS,
  DASHBOARD_PAYOUTS_PER_MIDS, DASHBOARD_AMOUNTS_PER_MID, DASHBOARD_MERCHANT_WITHOUT_ACTIVITY } from '@sm/actions/types';

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = {}, action) {
  state.update = action.type;
  switch(action.type) {
  case DASHBOARD_SELLS_EVENT:
    state.sells = action.payload;
    return state;
  case DASHBOARD_SETTLEMENTS:
    state.settlements = action.payload;
    return state;
  case DASHBOARD_PURCHASES_EVENT:
    state.purchases = action.payload;
    return state;
  case DASHBOARD_FTD_EVENT:
    state.ftds = action.payload;
    return state;
  case DASHBOARD_TRANSFER_EVENT:
    state.transfers = action.payload;
    return state;
  case DASHBOARD_REVENUE_EVENT:
    state.revenue = action.payload;
    return state;
  case DASHBOARD_PURCHASE_COUNTRIES_EVENT:
    state.purchaseMethods = action.payload;
    return state;
  case DASHBOARD_USERS_EVENT:
    state.users = action.payload;
    return state;
  case DASHBOARD_CUSTOMER_BALANCES_EVENT:
    state.general = action.payload;
    return state;
  case DASHBOARD_PIE_EVENT:
    state.pieData = action.payload;
    return state;
  case DASHBOARD_HISTOGRAM_EVENT:
    state.histogramData = action.payload;
    return state;
  case DASHBOARD_DEPOSITS_PER_MIDS:
    state.depositsPerMids = action.payload;
    return state;
  case DASHBOARD_PAYOUTS_PER_MIDS:
    state.payoutsPerMids = action.payload;
    return state;
  case DASHBOARD_WITHDRAWALS_EVENT:
    state.withdrawals = action.payload;
    return state;
  case DASHBOARD_PAYOUT_EVENT:
    state.payout = action.payload;
    return state;
  case DASHBOARD_PAYOUT_COUNTRIES_EVENT:
    state.payoutcountry = action.payload;
    return state;
  case DASHBOARD_AMOUNTS_PER_MID:
    state.amountsPerMid = action.payload;
    return state;
  case DASHBOARD_MERCHANT_WITHOUT_ACTIVITY:
    state.merchantsWithoutActivity = action.payload;
    return state;
  default:
    return state;
  }
}