import React, { Component } from 'react';
import { Panel } from "react-bootstrap";

import Spinner from '@sm/components/Spinner';
import Table from "@sm/components/custom/Table";

import '@assets/css/apiData.css';
import ExpandAllIcon from '@assets/images/expand-all-icon.png';
import CollapseAllIcon from '@assets/images/collapse-all-icon.png';

import { DASHBOARD_DEPOSITS_PER_MIDS } from '@sm/actions/types';
const store =  require('@sm/reducers/index');

class DashBoardDepositsPerMidComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deposits: undefined,
      loading: true,
      socket: false,
      expandAll: false,

      columns: [{
        value: "merchantName",
        label: "Merchant",
        leftAligned: true,
        isNumber: false
      }, {
        value: "midName",
        label: "MID",
        leftAligned: true,
        isNumber: false
      }, {
        value: "channel",
        label: "Provider",
        leftAligned: true,
        isNumber: true
      }, {
        value: "midId",
        label: "MID ID",
        isNumber: false,
        leftAligned: true,
      }, {
        value: "currency",
        label: "Currency",
        leftAligned: true,
        isNumber: false
      }, {
        value: "transactions",
        label: "Transactions",
        rightAligned: true,
        isNumber: true
      }, {
        value: "pending",
        label: "Pending",
        rightAligned: true,
        isNumber: true
      }, {
        value: "rejected",
        label: "Rejected",
        rightAligned: true,
        isNumber: true
      }, {
        value: "failed",
        label: "Failed",
        rightAligned: true,
        isNumber: true
      }, {
        value: "reversed",
        label: "Reversed",
        rightAligned: true,
        isNumber: true
      }, {
        value: "successful",
        label: "Successful",
        rightAligned: true,
        isNumber: true
      }, {
        value: "successRate",
        label: "Success Rate",
        rightAligned: true,
        isNumber: true
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_DEPOSITS_PER_MIDS && this.props.thisMonth && state.depositsPerMids.socket) {
        this.setState({ deposits: state.depositsPerMids, loading: false, socket: state.depositsPerMids.socket });
        return;
      }

      if (state.update === DASHBOARD_DEPOSITS_PER_MIDS && !state.depositsPerMids.socket) {
        this.setState({ deposits: state.depositsPerMids, loading: false, socket: state.depositsPerMids.socket });
      }
    });
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  getAggregatedInfo = ( obj, uniqueValue, sortBy, prop, isAverage, isCount ) => {
    let sum = 0;
    let count = 0;
    let successTrx = 0;
    let allTrx = 0;

    for( const el in obj ) {
      if( obj.hasOwnProperty(el) && (obj[el][sortBy] === uniqueValue) ) {
        sum += parseFloat(obj[el][prop]);
        count++;
        allTrx += parseFloat(obj[el]["attempts"]);
        successTrx += parseFloat(obj[el]["successful"]);
      }
    }
    if (isAverage) {
      return (successTrx / allTrx * 100).toFixed(0);
    }
    if (isCount) {
      return count;
    }
    return sum;
  };

  getChildNodes = (items, filterValue, sortBy) => {
    const copy = items.map(object => ({ ...object }));
    if (!copy) {
      return [];
    }
    return copy.filter(elem => elem[sortBy] === filterValue).map(item => {
      return Object.assign(item, {
        [sortBy]: "-",
        midId: item.midId,
        channel: sortBy === "channel" ? item.channel : item.channelId,
        transactions: item.attempts,
        successRate: item.successRate + "%"
      });
    });
  };

  getChildNodeProperty = (uniqueValue, fieldName, sortBy) => {
    const { deposits } = this.state;
    return deposits.find(deposit => deposit[sortBy] === uniqueValue)[fieldName];
  };

  getColumns = () => {
    const columns = [ ...this.state.columns ];
    const { sortBy } = this.props;

    if (sortBy === "channel") {
      [columns[0], columns[1], columns[2]] = [columns[2], columns[0], columns[1]];
      return columns;
    }
    if (sortBy === "currency") {
      [columns[0], columns[1], columns[2], columns[3], columns[4]] = [columns[4], columns[0], columns[1], columns[2], columns[3]];
      return columns;
    }
    return columns;
  };

  tableData = () => {
    const { deposits}  = this.state;
    const { sortBy } = this.props;

    if (!deposits) {
      return [];
    }

    const transformed = [];
    const unique = [...new Set(deposits.map(item => item[sortBy]))];

    for (let i = 0; i < unique.length; i++) {
      const mids = this.getAggregatedInfo(deposits, unique[i], sortBy, "midName", false, true);
      const midsCount = <span className="mids"> {`${mids} mid${mids !== 1 ? "s" : ""}`}</span>;
      transformed.push({
        merchantName: sortBy === "merchantName" ? unique[i] : (mids > 1 ? "" : this.getChildNodeProperty(unique[i], "merchantName", sortBy)),
        channel: sortBy === "channel" ? unique[i] : (mids > 1 ? "" : this.getChildNodeProperty(unique[i], "channelId", sortBy)),
        currency: sortBy === "currency" ? unique[i] : (mids > 1 ? "" : this.getChildNodeProperty(unique[i], "currency", sortBy)),
        midName: mids > 1 ? midsCount : this.getChildNodeProperty(unique[i], "midName", sortBy),
        midId: mids > 1 ? "" : this.getChildNodeProperty(unique[i], "midId", sortBy),
        transactions: this.getAggregatedInfo(deposits, unique[i], sortBy, "attempts"),
        pending: this.getAggregatedInfo(deposits, unique[i], sortBy, "pending"),
        failed: this.getAggregatedInfo(deposits, unique[i], sortBy, "failed"),
        rejected: this.getAggregatedInfo(deposits, unique[i], sortBy, "rejected"),
        successful: this.getAggregatedInfo(deposits, unique[i], sortBy, "successful"),
        reversed: this.getAggregatedInfo(deposits, unique[i], sortBy, "reversed"),
        successRate: this.getAggregatedInfo(deposits, unique[i], sortBy, "successRate", true) + "%",
        childNodes: this.getChildNodes(deposits, unique[i], sortBy)
      });
    }
    return transformed;
  };

  onExpandCollapseClick = () => {
    this.setState({
      expandAll: !this.state.expandAll
    });
  };

  render() {
    const { deposits, expandAll, loading } = this.state;

    let sellsWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> PAYMENTS PERFORMANCE PER MID </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && deposits) {
      sellsWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> PAYMENTS PERFORMANCE PER MID </Panel.Title>
            <img
              src={ expandAll ? CollapseAllIcon : ExpandAllIcon }
              alt={ `${expandAll ? 'collapse' : 'expand'} all rows` }
              className="expand-collapse-icon"
              onClick={ () => this.onExpandCollapseClick() }
            />
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content table-content payout-requests accordion-table">
              <Table
                columns={ this.getColumns() }
                headerColor="#223579"
                data={ this.tableData() }
                isStriped={ false }
                defaultSortBy="transactions"
                sortDirection="DESC"
                expandAllRows={ expandAll }
                useArrow={ true }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return sellsWrap;
  }

}

export default DashBoardDepositsPerMidComponent;