import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import { Column } from 'simple-flexbox';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

import Spinner from '@sm/components/Spinner';

class ModalView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0
    };
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  };

  handleResize = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  getWindowHeight = () => {
    return 600;
  };

  render() {
    const { showModal, handleClose, title, data, modalType, isLoading, buttonLabel } = this.props;
    const { height } = this.state;
    return (
      <Modal
        show={ showModal }
        onHide={ handleClose }
        backdrop="static"
        keyboard={ false }
        dialogClassName={ modalType === "iframe" ? "iframe-modal" : "" }
      >
        <Modal.Header closeButton={ modalType !== "iframe" }>
          <Modal.Title> { title } </Modal.Title>
          {modalType === "iframe" && (
            <button
              className="close-btn"
              onClick={ handleClose }
            >
              End session
            </button>
          )}
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
              <Spinner smallContainer={ true } />
            </div>
          ) : (
            <Column
              flexGrow={ 1 }
              className={ `${modalType === "iframe" && "iframe-modal-content"}` }
              style={ { padding: "25px", overflow: "auto" } }
            >
              { modalType === "json" ? (
                <JsonView src={ data } collapseStringsAfterLength={ 1000000 } enableClipboard={ false } />
              ) : modalType === "content" ? (
                <>
                  { data }
                </>
              ) : modalType === "iframe" ? (
                <iframe
                  allow="fullscreen"
                  width="100%"
                  height={ height - 45 }
                  frameBorder="0"
                  title="Login as user iframe"
                  src={ data }>
                </iframe>
              ) : (
                <p style={ { whiteSpace: "pre-wrap" } }>
                  { data }
                </p>
              )}
            </Column>
          )}
        </Modal.Body>
        {modalType !== "iframe" && (
          <Modal.Footer>
            <Button className="json-modal-close" onClick={ handleClose }>
              { buttonLabel || "Close" }
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

export default ModalView;
