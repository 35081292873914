import { crudService } from "@sm/services/crudService";

export const crudActions = {
  get,
  post,
  put,
  patch,
  remove
};

function get(endpoint, customHeaders = {}, skipApiPart) {
  return crudService
    .get(endpoint, customHeaders, skipApiPart)
    .catch(err => {
      return Promise.reject(err);
    })
    .then(handleSuccess, handleError);
}

function post(endpoint, jsonConfig, customHeaders = {}, isFile) {
  return crudService
    .post(endpoint, jsonConfig, customHeaders, isFile)
    .catch(err => {
      return Promise.reject(err);
    })
    .then(handleSuccess, handleError);
}

function put(endpoint, jsonConfig, customHeaders = {}, isFile) {
  return crudService
    .put(endpoint, jsonConfig, customHeaders, isFile)
    .catch(err => {
      return Promise.reject(err);
    })
    .then(handleSuccess, handleError);
}

function patch(endpoint, jsonConfig, customHeaders = {}) {
  return crudService
    .patch(endpoint, jsonConfig, customHeaders)
    .catch(err => {
      return Promise.reject(err);
    })
    .then(handleSuccess, handleError);
}

function remove(endpoint, jsonConfig, customHeaders = {}) {
  return crudService
    .remove(endpoint, jsonConfig, customHeaders)
    .catch(err => {
      return Promise.reject(err);
    })
    .then(handleSuccess, handleError);
}

function handleSuccess(resp) {
  return resp;
}

function handleError(err) {
  return err.json().then(json => {
    return Promise.reject(json);
  }).catch(
    error => {
      if (error.hasOwnProperty("message")) {
        if (error.message && error.message.includes("Unexpected token '<'")) {
          error.message = `Server couldn’t process your request at the moment (error ${err.status}) , please try again later.`;
        }
        return Promise.reject(error);
      } else {
        return Promise.reject({
          message: error
        });
      }
    }
  );
}