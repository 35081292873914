import React, { Component } from 'react';
import { Panel } from "react-bootstrap";

import Spinner from '@sm/components/Spinner';
import Table from "@sm/components/custom/Table";

import '@assets/css/apiData.css';

import { toFormattedNumber } from "@sm/utils/utils";
import { DASHBOARD_PURCHASES_EVENT } from '@sm/actions/types';
const store =  require('@sm/reducers/index');

class DashBoardPurchasesCountriesComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      purchases: undefined,
      loading: true,
      socket: false,
      columns: [ {
        value: "currency",
        label: "Currency",
        isNumber: true
      }, {
        value: "transactions",
        label: "Transactions",
        rightAligned: true
      }, {
        value: "successful",
        label: "Successful",
        rightAligned: true
      }, {
        value: "avgAmount",
        label: "Avg. Amount",
        rightAligned: true
      }, {
        value: "total",
        label: "Total",
        rightAligned: true
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_PURCHASES_EVENT && this.props.thisMonth && state.purchases.socket) {
        this.setState({ purchases: state.purchases, loading: false, socket: state.purchases.socket });
        return;
      }

      if (state.update === DASHBOARD_PURCHASES_EVENT && !state.purchases.socket) {
        this.setState({ purchases: state.purchases, loading: false, socket: state.purchases.socket });
      }
    });
  }

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  tableData = () => {
    const { purchases } = this.state;

    if (!purchases) {
      return [];
    }
    const firstColumn = this.state.columns[0].value;
    return purchases.sort((elem1, elem2) => elem1[firstColumn] ? elem1[firstColumn].localeCompare(elem2[firstColumn]) : 1)
      .map(elem => {
        return {
          currency: elem.currency,
          transactions: elem.attempts,
          successful: elem.successful,
          total: toFormattedNumber(elem.value),
          avgAmount: toFormattedNumber(elem.avgValue)
        };
      });
  };

  render() {
    const { purchases, loading, columns } = this.state;

    let purchasesWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> PAYMENTS </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && purchases) {
      purchasesWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> PAYMENTS </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content table-content">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return purchasesWrap;
  }

}

export default DashBoardPurchasesCountriesComponent;