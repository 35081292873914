import React, { Component } from "react";
import axios from "axios";
import Datetime from "react-datetime";
import { v4 as uuidv4 } from 'uuid';
import TextareaAutosize from 'react-textarea-autosize';
import { Row, Column } from "simple-flexbox";
import { Panel } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import PageHeader from "@sm/components/PageHeader";
import Form from "@sm/core/Form";
import Select from "@sm/components/custom/Select";
import Spinner from "@sm/components/Spinner";

import "@assets/css/generateOrder.css";
import GenerateOrderIcon from "@assets/images/notes.png";

import { IP_ADDRESS_REGEX } from "@sm/constants";
import { FETCH_PERMISSIONS } from "@sm/actions/types";
import { crudActions } from "@sm/services/crudActions";
const store = require("@sm/reducers/index");

class GenerateOrder extends Component {
  state = {
    lastUpdate: Datetime.moment().utc().format("HH:mm - DD. MMM YYYY"),
    
    loadingLookups: true,
    loadingDataInput: false,
    isSecondPanelVisible: false,

    controls: {},
    controlsFormData: {
      company: "",
      merchant: "",
      psp: "",
      midType: "",
      endpoint: "",
      cascading: "false"
    },
    controlsFormRules: [
      {
        name: "company",
        type: "isString",
        rules: {
          required: true,
        },
      },
      {
        name: "merchant",
        type: "isString",
        rules: {
          required: true,
        },
      },
      {
        name: "psp",
        type: "isString",
        rules: {
          required: true,
        },
      },
      {
        name: "midType",
        type: "isString",
        rules: {
          required: true,
        },
      },
      {
        name: "endpoint",
        type: "isString",
        rules: {
          required: true,
        },
      },
      {
        name: "cascading",
        type: "isString",
        rules: {
          required: true,
        },
      }
    ],

    inputsForm: new Form([], []),
    inputs: [],
    additionalInputs: [],
    additionalInputsForm: new Form([], []),

    companies: [],
    companiesClients: {},
    companiesPsps: [],
    midTypes: [],
    endpoints: [],
    mids: [],
    currencies: [],
    depositMethods: [],
    payoutMethods: [],
    bankcode: [],
    bankname: [],
    ipAddress: "",

    access: [],

    showAlert: false,
    alertType: "success",
    alertMessage: "",
    messageType: "",
    paymentUrl: ""
  };

  subscribeFunction = null;

  componentDidMount() {
    const { controlsFormRules, controlsFormData } = this.state;
    this.setState({
      controls: new Form(controlsFormData, controlsFormRules),
      loading : false
    });

    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access,
        });
      }
    });

    Promise.all([
      crudActions.get("v1/adminpanel/lookups"),
      crudActions.get("v1/data/input/lookups")
    ]).then((data) => {
      const adminLookups = data[0];
      const lookups = data[1];
      const companies = lookups.whiteLabels.map((elem) => elem.whitelabel);
      companies.sort((elemA, elemB) =>
        elemA.label.localeCompare(elemB.label)
      );

      this.setState({
        companies: companies,
        controls: Object.assign(this.state.controls, {
          company: companies[0] ? companies[0].value : "",
        }),
        countries: adminLookups.country || [],
        depositMethods: adminLookups.buyMethods || [],
        payoutMethods: adminLookups.payoutMethods || [],
        companiesClients: lookups.whiteLabels.reduce(
          (accumulator, currentElem) => {
            accumulator[currentElem.whitelabel.value] =
              currentElem.merchants;
            return accumulator;
          },
          {}
        ),
        companiesPsps: lookups.whiteLabels.reduce(
          (accumulator, currentElem) => {
            accumulator[currentElem.whitelabel.value] = currentElem.psp;
            return accumulator;
          },
          {}
        ),
        currencies: lookups.whiteLabels.reduce(
          (accumulator, currentElem) => {
            accumulator[currentElem.whitelabel.value] = currentElem.baseCurrencies;
            return accumulator;
          },
          {}
        ),
        mids: lookups.mids.map((mid) => {
          return {
            value: mid.id,
            label: `${mid.name} (${mid.id})`,
            clients: mid.clients,
            pspId: mid.pspId,
            currency: mid.currency,
            countries: mid.countries
          };
        }),
        payoutMids: lookups.payoutMids.map((mid) => {
          return {
            value: mid.id,
            label: mid.name,
            clients: mid.clients,
            pspId: mid.pspId,
            currency: mid.currency,
            countries: mid.countries
          };
        }),
        loadingLookups: false,
      }, () => {
        this.preselectFormValues();
      });
    }).catch((err) => {
      if (err && err.message) {
        this.setState({
          showAlert: true,
          alertType: "error",
          alertMessage: err.message
        });
      }
    });

    axios.get("https://api.ipify.org/?format=text")
      .then(ipData => {
        if (ipData) {
          const ipAddress = ipData.data;
          const reg = new RegExp(IP_ADDRESS_REGEX);
          this.setState({
            ipAddress: reg.test(ipAddress) ? ipAddress : ""
          });
        }
      });
  }

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  }

  getEndpoints = (client) => {
    crudActions.get(`v1/generate-order/endpoints?client=${client}`)
      .then((endpoints) => {
        if (endpoints) {
          const endpointsList = {};
          for (const [key] of Object.entries(endpoints)) {
            const sorted = endpoints[key].sort((elemA, elemB) => elemA.localeCompare(elemB));
            Object.assign(endpointsList, {
              [key]: sorted.map((item) => ({
                value: item,
                label: item
              }))
            });
          }

          const midTypes = Object.keys(endpoints).map((elem) => ({
            value: elem,
            label: elem.toLowerCase() === "deposit" ? "Payment" : elem.charAt(0).toUpperCase() + elem.slice(1),
          }));
          midTypes.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));

          this.setState({
            endpoints: endpointsList,
            midTypes: midTypes
          }, () => {
            if (midTypes.length) {
              this.onValueChange("controls", midTypes[0], "midType");
            }
            const hostedEndpoint = endpointsList[midTypes[0].value].find(el => el.value.includes("hosted"));
            if (hostedEndpoint) {
              this.onValueChange("controls", hostedEndpoint, "endpoint");
            }
          });
        }
      }).catch((err) => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message
          });
        }
      });
  };

  getInputs = (client, endpoint) => {
    this.setState({
      loadingDataInput: true,
      isSecondPanelVisible: true
    });
    crudActions.get(`v1/generate-order/inputs?client=${client}&url=${endpoint}`)
      .then((inputs) => {
        if (inputs && inputs.length) {
          this.processInputs(inputs, "inputs", "inputsForm");
        }
      }).catch((err) => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
            loadingDataInput: false
          });
        }
      });
  };

  preselectFormValues = () => {
    const { companiesClients, companiesPsps, controls } = this.state;
    this.onValueChange("controls", companiesClients[controls.company][0], "merchant");
    this.onValueChange("controls", companiesPsps[controls.company][0], "psp");
  };

  isNumberField = (field, formId) => {
    const currentInputs = this.state[formId.slice(0, -4)];
    const input = currentInputs.find(e => e.id === field);

    return input.inputType === "number";
  };

  onValueChange = (formId, event, field, shouldValidate) => {
    const { additionalInputsForm, inputsForm, inputs } = this.state;
    const controls = { ...this.state.controls };
    const selectedForm = this.state[formId];
    if (!event) {
      selectedForm[field] = !!event;
    } else if (event.target) {
      if (this.isNumberField(field, formId)) {
        selectedForm[field] = parseFloat(event.target.value);
      } else {
        selectedForm[field] = event.target.value;
      }
    } else if (event.value) {
      selectedForm[field] = event.value;
    } else {
      selectedForm[field] = event.map((elem) => elem.value);
    }

    if (formId !== "controls") {
      selectedForm.isFormValid();
    }

    if (field === "orderSample") {
      if (controls[field] !== event.value) {
        this.setState({
          additionalInputs: [],
          additionalInputsForm: new Form([], [])
        });
      }
      additionalInputsForm.resetValidation();
      inputsForm.resetValidation();
      this.setState({
        additionalInputsForm,
        inputsForm
      });
    }

    if (field === "midId") {
      if (event) {
        selectedForm.currency = event.currency;
        selectedForm.country = event.countries[0];
        this.getAdditionalFields(event.value);
      } else {
        this.setState({
          additionalInputs: [],
          additionalInputsForm: new Form([], [])
        });
      }
    }

    if (field === "cascading") {
      const midIdInputIndex = inputs.findIndex(i => i.identifier === "midId");
      const midIdField = inputsForm.fieldRules.find(rule => rule.name === "midId");
      inputs[midIdInputIndex].required = event.value === "false";
      midIdField.rules.required = event.value === "false";
      inputsForm.isFormValid();
    }

    this.setState({
      [formId]: selectedForm,
      ...(field === "cascading" && { inputsForm }),
      ...(field === "cascading" && { inputs }),
    }, () => {
      if (field === "company") {
        this.preselectFormValues();
      }
      if (field === "merchant") {
        this.getEndpoints(selectedForm.merchant);
      }
      if (field === "orderSample") {
        this.setDefaultInputsValues(event.value, false);
      }
    });
  };

  getAdditionalFields = (midId) => {
    const { controls, inputsForm } = this.state;
    crudActions.get(`v1/generate-order/inputs/additional?client=${controls.merchant}&url=${controls.endpoint}&midId=${midId}&paymentType=${controls.midType}`)
      .then(response => {
        if (response.amount && (!inputsForm.amount || (parseFloat(response.amount) > parseFloat(inputsForm.amount)))) {
          this.onValueChange("inputsForm", { value: response.amount.toString() }, "amount");
        }
        if (response.fields && response.fields.length) {
          this.processInputs(response.fields, "additionalInputs", "additionalInputsForm");
        } else {
          this.setState({
            additionalInputs: [],
            additionalInputsForm: new Form([], [])
          });
        }
      }).catch((err) => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
            additionalInputs: [],
            additionalInputsForm: new Form([], [])
          });
          inputsForm.midId = "";
        }
      });
  };

  getOrderSampleOptions = () => {
    return [{
      label: "Manual Entry",
      value: "setManually"
    }, {
      label: "Test Sample",
      value: "setDefault"
    }];
  };

  processInputs = (inputs, arrayId, formId) => {
    const { controls, ipAddress } = this.state;
    const fields = inputs.reduce((acc, cur) => ({
      ...acc, [cur.fieldName]: cur.identifier === "ip" ?
        ipAddress : (cur.fieldName === "transactionId" || cur.fieldName === "orderId") ? uuidv4() : (cur.forceDefault || (controls.orderSample === "setDefault" && formId !== "inputsForm")) ? cur.defaultValue || "" : ""
    }), {});
    const fieldsArray = inputs.map((input) => {
      if (input.dropdownValues && input.inputFieldType === "DROPDOWN") {
        this.setState({
          [input.identifier.toLowerCase()]: input.dropdownValues
        });
      }
      return {
        id: input.fieldName,
        defaultValue: input.defaultValue || "",
        inputFieldType: input.inputFieldType,
        ...(input.inputFieldType === "INPUT_FIELD" && { inputType: input.fieldType === "String" ? "text" : "number" }),
        label: input.fieldLabel,
        required: input.identifier === "midId" ? true : input.required, // mark MID ID as required field
        disabled: input.identifier === "ip" && ipAddress !== "",
        forceDefault: input.forceDefault,
        identifier: input.identifier
      };
    });
    const fieldsRules = inputs.map((input) => ({
      name: input.fieldName,
      type: input.identifier === "ip" || input.pattern ? "isPattern" : (input.fieldType === "String" ? "isString" : "isNumber"),
      rules: {
        required: input.identifier === "midId" ? true : input.required, // mark MID ID as required field
        ...(input.identifier === "ip" && { trim: true }),
        ...(input.identifier === "ip" && { pattern: IP_ADDRESS_REGEX }),
        ...(input.pattern && { pattern: input.pattern })
      }
    }));
    const inputsForm = new Form(fields, fieldsRules);
    this.setState({
      [formId]: inputsForm,
      [arrayId]: fieldsArray,
      loadingDataInput: false
    }, () => {
      if (formId === "inputsForm") {
        const currencyFieldName = inputs.find(elem => elem.identifier === "currency").fieldName;
        const countryFieldName = inputs.find(elem => elem.identifier === "country").fieldName;
        const currencies = this.getSelectOptions("currency");
        const countries = this.getSelectOptions("country");
        if (currencies.length === 1) {
          this.setState({
            inputsForm: Object.assign(this.state.inputsForm, {
              [currencyFieldName]: currencies[0].value
            })
          });
        }
        if (countries.length === 1) {
          this.setState({
            inputsForm: Object.assign(this.state.inputsForm, {
              [countryFieldName]: countries[0].value
            })
          });
        }
        this.onValueChange("controls", { label: "Test Sample", value: "setDefault" }, "orderSample", true);
      }
    });
  };

  getSelectOptions = (inputId) => {
    const { bankcode, bankname, controls, depositMethods, payoutMids, mids, payoutMethods } = this.state;
    if (!controls.psp || !controls.merchant) {
      return [];
    }

    if (this.state[inputId]) {
      return this.state[inputId];
    }

    if (inputId === "midId") {
      if (controls.midType === "deposit") {
        return mids.filter(
          (mid) =>
            mid.clients.some(
              (client) => client.clientId === parseInt(controls.merchant, 10)
            ) && controls["psp"] === mid.pspId.toString()
        );
      } else {
        return payoutMids.filter(
          (mid) =>
            mid.clients.some(
              (client) => client.clientId === parseInt(controls.merchant, 10)
            ) && controls["psp"] === mid.pspId.toString()
        );
      }
    }

    if (inputId === "currency") {
      const availableMids = this.getSelectOptions("midId");
      const availableCurrencies = [...new Set(availableMids.map(a => a.currency))];
      return availableCurrencies.sort().map((elem) => ({
        label: elem,
        value: elem
      }));
    }

    if (inputId === "country") {
      const availableMids = this.getSelectOptions("midId");
      const availableCountries = [...new Set(availableMids.map(a => a.countries.toString()))];
      return this.state.countries.filter(item => availableCountries.some(i => i.includes(item.value)));
    }

    if (inputId === "method") {
      if (controls.midType === "deposit") {
        return depositMethods;
      } else {
        return payoutMethods;
      }
    }

    if (inputId.toLowerCase() === "bankname") {
      return bankname;
    }

    if (inputId.toLowerCase() === "bankcode") {
      return bankcode;
    }
  };

  setDefaultInputsValues = (type, shouldValidate) => {
    const { additionalInputs, additionalInputsForm, inputs, inputsForm, ipAddress } = this.state;

    const inputsData = inputsForm.data();
    const additionalInputsData = additionalInputsForm.data();

    const defaultInputs = Object.keys(inputsData).reduce(function(elem, index) {
      const inputIndentifier = inputs.find(input => input.id === index).identifier;
      const inputDefaultValue = inputs.find(input => input.id === index).defaultValue;
      const inputForceDefault = inputs.find(input => input.id === index).forceDefault;

      // do not update ipAddress field or fields with property forceDefault: true
      elem[index] = inputIndentifier !== "ip" ?
        ((index === "transactionId" || index === "orderId") ? uuidv4() : 
          (type === "setDefault" ?
            inputsForm[index] ? inputsForm[index] : inputDefaultValue :
            (inputForceDefault ? inputDefaultValue : ""))) :
        ipAddress;
      return elem;
    }, {});

    let defaultAdditionalInputs = [];
    if (additionalInputs.length) {
      defaultAdditionalInputs = Object.keys(additionalInputsData).reduce(function(elem, index) {
        const inputDefaultValue = additionalInputs.find(input => input.id === index).defaultValue;
        const inputForceDefault = additionalInputs.find(input => input.id === index).forceDefault;

        // do not update fields with property forceDefault: true
        elem[index] = type === "setDefault" ? inputDefaultValue : (inputForceDefault ? inputDefaultValue : "");
        return elem;
      }, {});
    }

    if (defaultInputs["midId"]) {
      this.getAdditionalFields(defaultInputs["midId"]);
    }

    this.setState({
      inputsForm: Object.assign(inputsForm, defaultInputs),
      additionalInputsForm: Object.assign(additionalInputsForm, defaultAdditionalInputs)
    }, () => {
      if (shouldValidate) {
        this.state.inputsForm.isFormValid();
        this.state.additionalInputsForm.isFormValid();
        this.setState({
          inputsForm: this.state.inputsForm,
          additionalInputsForm: this.state.additionalInputsForm
        });
      }
    });
  };

  onBuildFormClick = () => {
    const { controls } = this.state;
    const isFormValid = controls.isFormValid();
    this.setState({
      controls
    });
    if (isFormValid) {
      this.setState({
        additionalInputs: [],
        additionalInputsForm: new Form([], [])
      }, () => {
        this.getInputs(controls.merchant, controls.endpoint);
      });
    }
  };

  onSendClick = (option) => {
    const { controls, inputsForm, additionalInputsForm } = this.state;
    const isControlsFormValid = controls.isFormValid();
    const isInputsFormValid = inputsForm.isFormValid();
    const isAdditionalInputsFormValid = additionalInputsForm.isFormValid();

    this.setState({
      controls,
      inputsForm,
      additionalInputsForm,
      messageType: option
    });

    if (isControlsFormValid && isInputsFormValid && isAdditionalInputsFormValid) {
      const { merchant, endpoint, cascading } = controls;

      const inputsData = inputsForm.data();

      const nonEmptyFields = Object.keys(inputsData).reduce(function(elem, index) {
        if (inputsData[index] !== "") elem[index] = inputsData[index];
        return elem;
      }, {});

      const mapPropName = controls.midType === "deposit" ? "map" : "data";
      const data = {
        clientId: merchant,
        url: endpoint,
        data: Object.assign(nonEmptyFields, {
          ...(Object.keys(additionalInputsForm.data()).length > 0 && {
            [mapPropName]: additionalInputsForm.data()
          })
        }),
        isCascade: cascading === 'true'
      };

      crudActions.post('v1/generate-order', data)
        .then((response) => {
          if (response && response.paymentUrl) {
            if (option === "open") {
              const url = response.paymentUrl.replace(' ','');
              const newTab = window.open(url, '_blank');
              if (newTab) {
                newTab.focus();
              }
            }
          }
          const resetInputsForm = new Form([], []);
          this.setState({
            ...(response.status === "REJECTED" && { showAlert: true }),
            ...(response.status === "REJECTED" && { alertType: "error" }),
            ...(response.status === "REJECTED" && { alertMessage: response.message }),
            ...(response.status !== "REJECTED" && { showAlert: true }),
            ...(response.status !== "REJECTED" && { alertType: "success" }),
            ...(response.status !== "REJECTED" && { alertMessage: option === "open" ? response.message : response.paymentUrl }),
            paymentUrl: response.paymentUrl,
            ...(response.status !== "REJECTED" && { inputs: [] }),
            ...(response.status !== "REJECTED" && { additionalInputs: [] }),
            ...(response.status !== "REJECTED" && { inputsForm: resetInputsForm }),
            ...(response.status !== "REJECTED" && { additionalInputsForm: resetInputsForm }),
            isSecondPanelVisible: response.status === "REJECTED",
            ...(response.status !== "REJECTED" && {
              controls: Object.assign(controls, {
                midType: "",
                endpoint: ""
              })
            })
          });
        })
        .catch((err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message.includes("No MID with given ID found") ? "Please check Cascading Settings to generate order." : err.message
            });
          }
        });
    } else {
      if (!isInputsFormValid) {
        const isIpAddressInvalid = this.state.inputsForm.errors.has("ipAddress");
        if (isIpAddressInvalid) {
          const { inputs } = this.state;
          const ipFieldIndex = inputs.findIndex(i => i.identifier === "ip");
          inputs[ipFieldIndex].disabled = false;
          this.setState({
            inputs,
            showAlert: true,
            alertType: "error",
            alertMessage: "Invalid IP address. Please try again or check your VPN connection."
          });
        }
      }
    }
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(
      (elem) => elem.permission === permissionName
    );
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  onCopyLink = () => {
    const { paymentUrl } = this.state;
    navigator.clipboard.writeText(paymentUrl);
    this.onAlertConfirm();
  };

  onAlertConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: "",
      messageType: ""
    });
  };

  render() {
    const {
      additionalInputs,
      additionalInputsForm,
      companies,
      companiesClients,
      companiesPsps,
      controls,
      endpoints,
      inputs,
      inputsForm,
      isSecondPanelVisible,
      loadingLookups,
      loadingDataInput,
      messageType,
      midTypes,
      showAlert,
      alertType,
      alertMessage
    } = this.state;

    const loadingContainer = (
      <div
        style={ {
          width: "100%",
          height: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        } }
      >
        <Spinner smallContainer={ true } />
      </div>
    );

    return (
      <Row flexGrow={ 1 } className="module apidata generateOrder" vertical="start">
        <Column flexGrow={ 1 }>
          <PageHeader
            title={ "Generate Order" }
            lastUpdate={ this.state.lastUpdate }
            img={ GenerateOrderIcon }
          />
          <Row flexGrow={ 1 } horizontal="start" wrap={ true } vertical="start">
            <Column
              flexGrow={ 1 }
              vertical="start"
              style={ {
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 15,
                width: "100%",
              } }
            >
              <Panel>
                <Panel.Heading>
                  <Panel.Title>SELECT</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  {loadingLookups ? (
                    <>{ loadingContainer }</>
                  ) : (
                    <div
                      className="panel-content"
                      style={ { overflow: "unset" } }
                    >
                      <Row
                        wrap={ true }
                        horizontal="start"
                        vertical="end"
                        style={ { width: "100%" } }
                      >
                        {/* WHITE LABEL */}

                        <Column
                          flexGrow={ 1 }
                          vertical="start"
                          alignSelf="start"
                          className="input-column"
                        >
                          <label> White Label </label>
                          <Select
                            id="company"
                            name="company"
                            value={ controls.company || "" }
                            className={
                              controls.errors.has("company")
                                ? "error-field"
                                : ""
                            }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) =>
                              this.onValueChange("controls", value, "company")
                            }
                            options={ companies }
                          />
                        </Column>

                        {/* MERCHANT */}

                        <Column
                          flexGrow={ 1 }
                          vertical="start"
                          alignSelf="start"
                          className="input-column"
                        >
                          <label> Merchant </label>
                          <Select
                            id="merchant"
                            name="merchant"
                            value={ controls.merchant || "" }
                            className={
                              controls.errors.has("merchant")
                                ? "error-field"
                                : ""
                            }
                            required={ true }
                            clearable={ false }
                            disabled={ !controls.company }
                            onChange={ (value) =>
                              this.onValueChange("controls", value, "merchant")
                            }
                            options={
                              controls.company
                                ? companiesClients[controls.company]
                                : []
                            }
                          />
                        </Column>

                        {/* Provider */}

                        <Column
                          flexGrow={ 1 }
                          vertical="start"
                          alignSelf="start"
                          className="input-column"
                        >
                          <label> Provider </label>
                          <Select
                            id="psp"
                            name="psp"
                            value={ controls.psp || "" }
                            className={
                              controls.errors.has("psp")
                                ? "error-field"
                                : ""
                            }
                            required={ true }
                            clearable={ false }
                            disabled={ !controls.company }
                            onChange={ (value) =>
                              this.onValueChange("controls", value, "psp")
                            }
                            options={
                              controls.company
                                ? companiesPsps[controls.company]
                                : []
                            }
                          />
                        </Column>

                        {/* MID Type */}

                        <Column
                          flexGrow={ 1 }
                          vertical="start"
                          alignSelf="start"
                          className="input-column"
                        >
                          <label> Mid Type </label>
                          <Select
                            id="midType"
                            name="midType"
                            value={ controls.midType || "" }
                            className={
                              controls.errors.has("midType")
                                ? "error-field"
                                : ""
                            }
                            required={ true }
                            clearable={ false }
                            disabled={ !controls.company }
                            onChange={ (value) =>
                              this.onValueChange("controls", value, "midType")
                            }
                            options={ midTypes }
                          />
                        </Column>
                      </Row>

                      <Row
                        flexGrow={ 1 }
                        wrap={ true }
                        horizontal="start"
                        vertical="end"
                        style={ { width: "100%" } }
                      >
                        {/* Endpoints */}

                        <Column
                          flexGrow={ 1 }
                          vertical="start"
                          alignSelf="start"
                          className="input-column"
                        >
                          <label> Endpoints </label>
                          <Select
                            id="endpoint"
                            name="endpoint"
                            value={ controls.endpoint || "" }
                            className={
                              controls.errors.has("endpoint")
                                ? "error-field"
                                : ""
                            }
                            required={ true }
                            clearable={ false }
                            disabled={ !controls.company }
                            onChange={ (value) =>
                              this.onValueChange("controls", value, "endpoint")
                            }
                            options={ endpoints[controls.midType] }
                          />
                        </Column>

                        { [0, 2].map(item => 
                          <Column
                            key={ item }
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <span/>
                          </Column>
                        ) }
                        <Column
                          flexGrow={ 1 }
                          vertical="start"
                          alignSelf="start"
                          className="input-column"
                        >
                          <label/>
                          <CustomButton
                            title="Build form"
                            type="button"
                            disabled={ false }
                            onClick={ (e) => this.onBuildFormClick() }
                          />
                        </Column>
                      </Row>

                      {isSecondPanelVisible && (
                        <>
                          {loadingDataInput && (
                            <>{ loadingContainer }</>
                          )}

                          {inputs.length > 0 && !loadingDataInput && (
                            <Row
                              wrap={ true }
                              horizontal="start"
                              vertical="end"
                              style={ { width: "calc(100% - 20px)", margin: "10px" } }
                            >
                              <Panel>
                                <Panel.Heading>
                                  <Panel.Title>ORDER FIELDS</Panel.Title>
                                </Panel.Heading>
                                <Panel.Body>
                                  <Row
                                    wrap={ true }
                                    horizontal="start"
                                    vertical="end"
                                    style={ { width: "100%" } }
                                  >
                                    <Column
                                      flexGrow={ 1 }
                                      vertical="start"
                                      alignSelf="start"
                                      className="input-column"
                                    >
                                      <label> Order Sample </label>
                                      <Select
                                        id="orderSample"
                                        name="orderSample"
                                        value={ controls.orderSample || "" }
                                        className={
                                          controls.errors.has("orderSample")
                                            ? "error-field"
                                            : ""
                                        }
                                        required={ true }
                                        clearable={ false }
                                        onChange={ (value) =>
                                          this.onValueChange("controls", value, "orderSample")
                                        }
                                        options={ this.getOrderSampleOptions() }
                                      />
                                    </Column>
                                    {inputs.map(input =>
                                      <Column
                                        key={ input.id }
                                        flexGrow={ 1 }
                                        vertical="start"
                                        alignSelf="start"
                                        className="input-column"
                                      >
                                        <label> {input.label}{input.required && '*'} </label>
                                        {input.inputFieldType === "DROPDOWN" ? (
                                          <Select
                                            className={ inputsForm.errors.has(input.id) ? 'error-field' : "" }
                                            value={ inputsForm[input.id] }
                                            required={ input.required }
                                            clearable={ true }
                                            withId={ input.identifier === "midId" }
                                            options={ this.getSelectOptions(input.identifier) }
                                            onChange={ (e) => this.onValueChange("inputsForm", e, input.id) }
                                          />
                                        ) : input.inputFieldType === "TEXTAREA" ? (
                                          <TextareaAutosize
                                            cacheMeasurements
                                            value={ inputsForm[input.id] }
                                            disabled={ input.disabled }
                                            className={ "form-control " + (inputsForm.errors.has(input.id) ? 'error-field' : "") }
                                            onChange={ (e) => this.onValueChange("inputsForm", e, input.id) }
                                          />
                                        ) : (
                                          <>
                                            <Tooltip id={ input.id } />
                                            <div
                                              data-tooltip-id={ input.id }
                                              data-tooltip-place="top"
                                              data-tooltip-variant="light"
                                              data-tooltip-content={ inputsForm[input.id] ? inputsForm[input.id] : null }>
                                              <input
                                                type={ input.inputType }
                                                className={ "form-control " + (inputsForm.errors.has(input.id) ? 'error-field' : "") }
                                                value={ inputsForm[input.id] }
                                                disabled={ input.disabled }
                                                onChange={ (e) => this.onValueChange("inputsForm", e, input.id) }
                                              />
                                            </div>
                                          </>
                                        )}
                                      </Column>
                                    )}
                                    {additionalInputs.map(input =>
                                      <Column
                                        key={ input.id }
                                        flexGrow={ 1 }
                                        vertical="start"
                                        alignSelf="start"
                                        className="input-column"
                                      >
                                        <label> {input.label}{input.required && '*'} </label>
                                        {input.inputFieldType === "DROPDOWN" ? (
                                          <Select
                                            id={ input.id }
                                            className={ additionalInputsForm.errors.has(input.id) ? 'error-field' : "" }
                                            value={ additionalInputsForm[input.id] }
                                            required={ input.required }
                                            clearable={ true }
                                            withId={ false }
                                            options={ this.getSelectOptions(input.identifier) }
                                            onChange={ (e) => this.onValueChange("additionalInputsForm", e, input.id) }
                                          />
                                        ) : input.inputFieldType === "INPUT_FIELD" ? (
                                          <>
                                            <Tooltip id={ input.id } />
                                            <div
                                              data-tooltip-id={ input.id }
                                              data-tooltip-place="top"
                                              data-tooltip-variant="light"
                                              data-tooltip-content={ additionalInputsForm[input.id] ? additionalInputsForm[input.id] : null }>
                                              <input
                                                type={ input.inputType }
                                                className={ "form-control " + (additionalInputsForm.errors.has(input.id) ? 'error-field' : "") }
                                                value={ additionalInputsForm[input.id] }
                                                disabled={ input.disabled }
                                                onChange={ (e) => this.onValueChange("additionalInputsForm", e, input.id) }
                                              />
                                            </div>
                                          </>
                                        ) : <></>}
                                      </Column>
                                    )}
                                    {controls.midType === "deposit" && (
                                      <Column
                                        flexGrow={ 1 }
                                        vertical="start"
                                        alignSelf="start"
                                        className="input-column"
                                      >
                                        <label> Cascading </label>
                                        <Select
                                          id="cascading"
                                          name="cascading"
                                          value={ controls.cascading || "false" }
                                          className={
                                            controls.errors.has("cascading")
                                              ? "error-field"
                                              : ""
                                          }
                                          required={ true }
                                          clearable={ false }
                                          onChange={ (value) =>
                                            this.onValueChange("controls", value, "cascading")
                                          }
                                          options={ [
                                            {label: "Yes", value: "true"},
                                            {label: "No", value: "false"}
                                          ] }
                                        />
                                      </Column>
                                    )}
                                      
                                  </Row>
                                </Panel.Body>
                              </Panel>
                            </Row>
                          )}
                          
                          {!loadingDataInput && (
                            <Row
                              wrap={ true }
                              horizontal="end"
                              vertical="end"
                              style={ { width: "100%" } }
                              className="generate-order-actions"
                            >
                              {/* GET LINK Button */}
                              {inputs.length > 0 && (controls.midType === "deposit" || controls.midType === "topUp") && (
                                <Column
                                  flexGrow={ 0.1 }
                                  className="input-column fullwidth-button"
                                >
                                  <CustomButton
                                    title="Get test link"
                                    type="button"
                                    className="btn-secondary"
                                    disabled={ inputs.length === 0 }
                                    onClick={ (e) => this.onSendClick("copy") }
                                  />
                                </Column>
                              )}
                              {/* SEND Button */}
                              <Column
                                flexGrow={ 0.1 }
                                className="input-column fullwidth-button"
                              >
                                <CustomButton
                                  title="Send"
                                  type="button"
                                  disabled={ inputs.length === 0 }
                                  onClick={ (e) => this.onSendClick("open") }
                                />
                              </Column>
                            </Row>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            confirmButtonText={ messageType === "open" ? "Ok" : "Copy link" }
            onConfirm={ messageType === "open" ? this.onAlertConfirm : this.onCopyLink }
          />
        )}

      </Row>
    );
  }
}

export default GenerateOrder;
